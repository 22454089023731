import React from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Navbar, Tag, Menu } from 'react-bulma-components/dist';
import { pages, departments, screen, screenMenuLink, screenForRoute } from '../../utilities/enums'
import { giveBaseName } from '../../utilities/utils'
import { Link, NavLink } from 'react-router-dom'
import { getCurrentUser } from '../../utilities/Store';
import { hasFactory } from '../../utilities/clientInfo';


const MenuProvider = (props) => {

    console.log('Attained Base Name = ' + giveBaseName())

    let cu = getCurrentUser()

    let screenByRoute = (checkScreen) => {
        // let path = props.location.pathname
        // let path = window.location.pathname.split('/');
        let path = window.location.pathname


        // console.log('SENDING PATH =' + path)

        let thisScreen = screenForRoute(path)

        // console.log('SCREEN =' + thisScreen)


        // console.log('CHECKING. Calcuated Screen =' + thisScreen + ' . With Check Screen = ' + checkScreen)

        return thisScreen === checkScreen

        // let links = path.split('/')
        // // removing initial blank item after split
        // links.shift()
        // return links
    }


    const createNavBarItem = (screen) => {
        return (
            <Navbar.Item href={screenMenuLink(screen)} key={screen} active={screenByRoute(screen)}  >
                {screen}
            </Navbar.Item>
        )
    }

    const createMenuBarItem = (screen) => {
        return (
            <Menu.List.Item href={screenMenuLink(screen)} key={screen} active={screenByRoute(screen)}   >
                {screen}
            </Menu.List.Item>
        )
    }


    const storeMenu = (opened) => {
        let item = <Menu.List title="Store" > </Menu.List>

        if (props.isNavBar === true) {
            item = <Navbar.Link arrowless={false} onClick={() => { props.navdeptoggle(props.department) }} > Store </Navbar.Link>
        }

        if (opened) {
            // item =  (
            //         // <Menu.List title="STORE"> 
            //         // <Menu.List.Item active = {currentDept === departments.Store} className="menuCategory"> 
            //     <Menu.List title="Store" >
            //     <Menu.List.Item active = { props.currentScreen === screen.NewOrder } onClick={() => { props.linkClickedTo(screen.NewOrder) }} >
            //         New Orders
            //     </Menu.List.Item>
            //     <Menu.List.Item active = { props.currentScreen === screen.ProcessOrder } onClick={() => { props.linkClickedTo(screen.ProcessOrder) }} >
            //         Factory Window
            //     </Menu.List.Item>
            //     <Menu.List.Item active = { props.currentScreen === screen.CustomerDealing } onClick={() => { props.linkClickedTo(screen.CustomerDealing) }} >
            //         Client Window
            //     </Menu.List.Item>
            //     <Menu.List.Item active = { props.currentScreen === screen.Search_Store } onClick={() => { props.linkClickedTo(screen.Search_Store) }} >
            //         Search
            //     </Menu.List.Item>
            //     </Menu.List>
            //         // </Menu.List.Item>
            //         // </Menu.List> 
            // )


            let itemsToCreate = []

            if (cu.isAdmin) {
                if (hasFactory) {
                                        // itemsToCreate.push(screen.PendingInfo)
                    itemsToCreate = [screen.NewOrder, screen.RecentOrders ,screen.FactoryWindow, screen.VendorWindow, screen.ClientWidnow, screen.Search_Store]
                }else{
                    itemsToCreate = [screen.NewOrder, screen.RecentOrders , screen.VendorWindow, screen.ClientWidnow, screen.Search_Store]
                }
            } else {
                if (cu.position.control.ordersControl.storeControl.newOrders) {
                    itemsToCreate.push(screen.NewOrder)
                    itemsToCreate.push(screen.RecentOrders)
                    // itemsToCreate.push(screen.PendingInfo)
                }
                if (cu.position.control.ordersControl.storeControl.sendOrders || cu.position.control.ordersControl.storeControl.recieveOrders) {
                    if (hasFactory) {
                        itemsToCreate.push(screen.FactoryWindow)
                    }
                    itemsToCreate.push(screen.VendorWindow)
                }

                if (cu.position.control.ordersControl.storeControl.approveTrial || cu.position.control.ordersControl.storeControl.approveFinishing || cu.position.control.ordersControl.storeControl.approveDelivery) {
                    itemsToCreate.push(screen.ClientWidnow)
                }

                if (cu.position.control.ordersControl.storeControl.viewStatus || cu.position.control.ordersControl.storeControl.searchOrders) {
                    itemsToCreate.push(screen.Search_Store)
                }

            }




            if (props.isNavBar === true) {

                item = (
                    <div>
                        <Navbar.Link arrowless={false} onClick={() => { props.navdeptoggle(props.department) }} > Store </Navbar.Link>
                        <Navbar.Dropdown>
                            {itemsToCreate.map(itm => {
                                return createNavBarItem(itm)
                            })}
                        </Navbar.Dropdown>
                    </div>
                )

            } else {
                item = (
                    <div>
                        <Menu.List title="Store">
                            {itemsToCreate.map(itm => {
                                return createMenuBarItem(itm)
                            })}
                        </Menu.List>
                    </div>
                )
            }

        }
        return item
    }

    const factoryMenu = (opened) => {
        let item = <Menu.List title="Factory" > </Menu.List>

        if (props.isNavBar === true) {
            item = <Navbar.Link arrowless={false} onClick={() => { props.navdeptoggle(props.department) }} > Factory </Navbar.Link>
        }

        if (opened) {


            let itemsToCreate = []

            if (cu.isAdmin){
                 itemsToCreate = [screen.OrdersRecieved_Factory, screen.CompleteOrders_Factory, screen.SendOrders_Factory, screen.Search_Factory]
            }else{
                if (cu.position.control.ordersControl.factoryControl.confirmOrders) {
                    itemsToCreate.push(screen.OrdersRecieved_Factory)
                }
    
                if (cu.position.control.ordersControl.factoryControl.completeOrders) {
                    itemsToCreate.push(screen.CompleteOrders_Factory)
                }
    
                if (cu.position.control.ordersControl.factoryControl.sendOrders) {
                    itemsToCreate.push(screen.SendOrders_Factory)
                }
    
                if (cu.position.control.ordersControl.factoryControl.searchOrders) {
                    itemsToCreate.push(screen.Search_Factory)
                }
            }




            if (props.isNavBar === true) {

                item = (
                    <div>
                        <Navbar.Link arrowless={false} onClick={() => { props.navdeptoggle(props.department) }} > Factory </Navbar.Link>
                        <Navbar.Dropdown>
                            {itemsToCreate.map(itm => {
                                return createNavBarItem(itm)
                            })}
                        </Navbar.Dropdown>
                    </div>
                )

            } else {
                item = (
                    <div>

                        <Menu.List title="Factory">
                            {itemsToCreate.map(itm => {
                                return createMenuBarItem(itm)
                            })}
                        </Menu.List>
                    </div>
                )
            }
        }
        return item
    }

    const vendorMenu = (opened) => {
        let item = <Menu.List title="Vendors" > </Menu.List>

        if (props.isNavBar === true) {
            item = <Navbar.Link arrowless={false} onClick={() => { props.navdeptoggle(props.department) }} > Vendors </Navbar.Link>
        }

        if (opened) {

            let itemsToCreate = []

            if (cu.isAdmin){
                 itemsToCreate = [screen.Vendors, screen.OrdersRecieved_Vendor, screen.CompleteOrders_Vendor, screen.SendOrders_Vendor ]
            }else{

                if (cu.position.control.ordersControl.vendorControl.isAllowed) {
                    itemsToCreate.push(screen.Vendors)
                }

                if (cu.position.control.ordersControl.vendorControl.confirmOrders) {
                    itemsToCreate.push(screen.OrdersRecieved_Vendor)
                }
    
                if (cu.position.control.ordersControl.vendorControl.completeOrders) {
                    itemsToCreate.push(screen.CompleteOrders_Vendor)
                }
    
                if (cu.position.control.ordersControl.vendorControl.sendOrders) {
                    itemsToCreate.push(screen.SendOrders_Vendor)
                }
            }





            if (props.isNavBar === true) {

                item = (
                    <div>
                        <Navbar.Link arrowless={false} onClick={() => { props.navdeptoggle(props.department) }} > Vendors </Navbar.Link>
                        <Navbar.Dropdown>
                            {itemsToCreate.map(itm => {
                                return createNavBarItem(itm)
                            })}
                        </Navbar.Dropdown>
                    </div>
                )

            } else {
                item = (
                    <div>

                        <Menu.List title="Vendors">
                            {itemsToCreate.map(itm => {
                                return createMenuBarItem(itm)
                            })}
                        </Menu.List>
                    </div>
                )
            }
        }
        return item
    }

    const clientsMenu = (opened) => {
        let item = <Menu.List title="Clients" > </Menu.List>

        if (props.isNavBar === true) {
            item = <Navbar.Link arrowless={false} onClick={() => { props.navdeptoggle(props.department) }} > Clients </Navbar.Link>
        }

        if (opened) {

            let itemsToCreate = []

            if (cu.isAdmin){
                 itemsToCreate = [screen.AllClients, screen.Search_Clients]
            }else{
                if (cu.position.control.clientsControl.recentClients) {
                    itemsToCreate.push(screen.AllClients)
                }
    
                if (cu.position.control.clientsControl.searchClients) {
                    itemsToCreate.push(screen.Search_Clients)
                }
            }




            if (props.isNavBar === true) {

                item = (
                    <div>
                        <Navbar.Link arrowless={false} onClick={() => { props.navdeptoggle(props.department) }} > Clients </Navbar.Link>
                        <Navbar.Dropdown>
                            {itemsToCreate.map(itm => {
                                return createNavBarItem(itm)
                            })}
                        </Navbar.Dropdown>
                    </div>
                )

            } else {
                item = (
                    <div>

                        <Menu.List title="Clients">
                            {itemsToCreate.map(itm => {
                                return createMenuBarItem(itm)
                            })}
                        </Menu.List>
                    </div>
                )
            }
        }
        return item
    }

    const stocksMenu = (opened) => {
        let item = <Menu.List title="Stocks" > </Menu.List>

        if (props.isNavBar === true) {
            item = <Navbar.Link arrowless={false} onClick={() => { props.navdeptoggle(props.department) }} > Stocks </Navbar.Link>
        }

        if (opened) {
            let itemsToCreate = [screen.StockTypes, screen.Search_Stocks]

            if (props.isNavBar === true) {

                item = (
                    <div>
                        <Navbar.Link arrowless={false} onClick={() => { props.navdeptoggle(props.department) }} > Stocks </Navbar.Link>
                        <Navbar.Dropdown>
                            {itemsToCreate.map(itm => {
                                return createNavBarItem(itm)
                            })}
                        </Navbar.Dropdown>
                    </div>
                )

            } else {
                item = (
                    <div>

                        <Menu.List title="Stocks">
                            {itemsToCreate.map(itm => {
                                return createMenuBarItem(itm)
                            })}
                        </Menu.List>
                    </div>
                )
            }
        }
        return item
    }

    const reportsMenu = (opened) => {
        let item = <Menu.List title="Reports" > </Menu.List>

        if (props.isNavBar === true) {
            item = <Navbar.Link arrowless={false} onClick={() => { props.navdeptoggle(props.department) }} > Reports </Navbar.Link>
        }

        if (opened) {
            let itemsToCreate = []

            if (cu.isAdmin){
                itemsToCreate = [screen.ClientReports, screen.OrderReports, screen.ProductReports]
            }else{
                if (cu.position.control.reportsControl.clientReports) {
                    itemsToCreate.push(screen.ClientReports)
                }
    
                if (cu.position.control.reportsControl.orderReports) {
                    itemsToCreate.push(screen.OrderReports)
                }
    
                if (cu.position.control.reportsControl.productReports) {
                    itemsToCreate.push(screen.ProductReports)
                }
            }




            if (props.isNavBar === true) {

                item = (
                    <div>
                        <Navbar.Link arrowless={false} onClick={() => { props.navdeptoggle(props.department) }} > Reports </Navbar.Link>
                        <Navbar.Dropdown>
                            {itemsToCreate.map(itm => {
                                return createNavBarItem(itm)
                            })}
                        </Navbar.Dropdown>
                    </div>
                )

            } else {
                item = (
                    <div>

                        <Menu.List title="Reports">
                            {itemsToCreate.map(itm => {
                                return createMenuBarItem(itm)
                            })}
                        </Menu.List>
                    </div>
                )
            }
        }
        return item
    }

    const usersMenu = (opened) => {
        let item = <Menu.List title="Users" > </Menu.List>

        if (props.isNavBar === true) {
            item = <Navbar.Link arrowless={false} onClick={() => { props.navdeptoggle(props.department) }} > Users </Navbar.Link>
        }

        if (opened) {
            let itemsToCreate = [screen.Positions, screen.Users]

            if (props.isNavBar === true) {

                item = (
                    <div>
                        <Navbar.Link arrowless={false} onClick={() => { props.navdeptoggle(props.department) }} > Users </Navbar.Link>
                        <Navbar.Dropdown>
                            {itemsToCreate.map(itm => {
                                return createNavBarItem(itm)
                            })}
                        </Navbar.Dropdown>
                    </div>
                )

            } else {
                item = (
                    <div>

                        <Menu.List title="Users">
                            {itemsToCreate.map(itm => {
                                return createMenuBarItem(itm)
                            })}
                        </Menu.List>
                    </div>
                )
            }
        }
        return item
    }

    const settingsMenu = (opened) => {
        let item = <Menu.List title="Settings" > </Menu.List>

        if (props.isNavBar === true) {
            item = <Navbar.Link arrowless={false} onClick={() => { props.navdeptoggle(props.department) }} > Settings </Navbar.Link>
        }

        if (opened) {
            let itemsToCreate = [screen.Settings]

            if (props.isNavBar === true) {

                item = (
                    <div>
                        <Navbar.Link arrowless={false} onClick={() => { props.navdeptoggle(props.department) }} > Settings </Navbar.Link>
                        <Navbar.Dropdown>
                            {itemsToCreate.map(itm => {
                                return createNavBarItem(itm)
                            })}
                        </Navbar.Dropdown>
                    </div>
                )

            } else {
                item = (
                    <div>

                        <Menu.List title="Settings">
                            {itemsToCreate.map(itm => {
                                return createMenuBarItem(itm)
                            })}
                        </Menu.List>
                    </div>
                )
            }
        }
        return item
    }


    const thisMenuItem = (department, opened) => {
        let thisItem = null

        switch (department) {
            case departments.Store: thisItem = storeMenu(opened); break;
            case departments.Factory: thisItem = factoryMenu(opened); break;
            case departments.Vendors: thisItem = vendorMenu(opened); break;
            case departments.Transit: thisItem = null; break;
            case departments.Clients: thisItem = clientsMenu(opened); break;
            // case departments.Stocks: thisItem = stocksMenu(opened); break;
            case departments.Reports: thisItem = reportsMenu(opened); break;
            case departments.Users: thisItem = usersMenu(opened); break;

            case departments.Slides: thisItem = null; break;
            case departments.Settings: thisItem = settingsMenu(opened); break;

            default: break;

        }
        return thisItem

    }


    return (
        thisMenuItem(props.department, props.opened)
    )
}

export default MenuProvider
