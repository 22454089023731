import React from 'react'
import { Box, Button, Modal, Heading, Columns } from 'react-bulma-components/dist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPrint } from '@fortawesome/free-solid-svg-icons';
import ColumnAux from '../../containers/ColumnAux'
import { Formik, Form, Field } from 'formik'
import { Customer } from '../../utilities/Classes'
import { CustomerFilter, emptyCustomerFilter } from '../../utilities/Filters'
import cogoToast from 'cogo-toast';
import { countryList } from '../../utilities/countryOpts';
import { client_defaultCountry, client_favCountryList } from '../../utilities/clientInfo';

const CustomerSearchModal = (props) => {

    const setClosed = () => {
        props.closed()
    }

    let initialValues = {
        searchType: '',
        country: client_defaultCountry,
        phone1: '',
        // email1: ''    
    }



    const searchCustomer = (values, callback) => {

        if (!values.phone1 || values.phone1 === "") {
            callback({ success: false, customer: null, msg: "" })
            return
        }

        // console.log('Searching Customer with phone ' + values.country + '-' + values.phone1)
        let cf = new CustomerFilter(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)

        cf.phone = values.phone1

        Customer.getAll(cf, (success, customers, errMsg) => {
            if (success === true) {
                // console.log('Found Customers ')
                // console.log(customers)

                callback({ success: true, customer: customers[0], msg: '' })
            } else {
                cogoToast.error('Could not find Customer.')
                // console.log('Customer Save Failed')
                callback({ success: false, customer: null, msg: errMsg })
            }
        })


    }





    const form = () => {


        return (

            <div className='is-two-fifths  paddingHor20'>
                {/* <div className='is-two-fifths paymentBoxBGColor paddingHor20'> */}

                <br />
                {/* <Heading size={7}> SHIPPING ADDRESS </Heading> */}

                <Formik
                    initialValues={initialValues}

                    validate={(values) => {
                        const errors = {};
                        return errors;
                    }}

                    onSubmit={(data, { setSubmitting, resetForm }) => {
                        setSubmitting(true)
                        // console.log('Searching Customer with Data : ', data)
                        searchCustomer(data, (response) => {
                            setSubmitting(false)
                            resetForm(true)

                            if (response.success === true) {
                                if (response.customer) {
                                    props.customerFound(response.customer)
                                } else {
                                    props.customerWasNotFound(data.phone1)
                                }
                            }
                        })


                    }}

                    validator={() => ({})}

                >
                    {({ values, isSubmitting, errors, touched, isValidating }) => (

                        <Form >
                            {/* <div className='field is-horizontal  '> */}
                            {/* <label className="label type55 marginHorizontal8 marginTop20 ">Type</label> */}

                            <div className='field marginHorizontal8 has-addons  is-fullwidth is-expanded'>
                                {/* <label className="label type60 ">Phone</label> */}
                                <p className="control">
                                    <span className="select has-text-weight-semibold type65">
                                        <Field as='select' className='select' name='country'>
                                            <optgroup label="Fav">
                                                {client_favCountryList()}
                                            </optgroup>

                                            <optgroup label="All">
                                                {
                                                    countryList.map((thisCountry) => {
                                                        let val = `${thisCountry.ccode} (${thisCountry.mcode})`
                                                        return <option value={val} >{val}</option>
                                                    })
                                                }
                                                {/* <option value='NA (+264)' >NA (+264)</option>
                                            <option value='BW (+267)'  >BW (+267)</option>
                                            <option value='ZW (+263)'  >ZW (+263)</option>
                                            <option value='ZA (+27)' >ZA (+27)</option>
                                            <option value='DE (+49)'  >DE (+49)</option> */}
                                            </optgroup>

                                        </Field>
                                    </span>
                                </p>
                                <p className="control is-fullwidth is-expanded">
                                    <Field name='phone1' type='input' className='input has-text-weight-semibold type65 is-fullwidth is-expanded' placeholder='Phone' />
                                </p>

                            </div>

                            <br />
                            <br />


                            <Button disabled={isSubmitting || isValidating} loading={isSubmitting} className='button is-black is-fullwidth marginTop10' type='submit' > Search </Button>

                            <br />

                        </Form>

                    )}

                </Formik>
            </div>
        )

    }


    const modal = () => {
        return (
            <Modal show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={() => setClosed()} >
                <Modal.Content className='is-padingless'>
                    <Box className='is-radiusless is-padingless fadeIn has-background-light'>
                        <Heading size={6} className='is-size-5-mobile has-text-black has-text-semibold has-text-centered' > Search Customer </Heading>
                        <ColumnAux isMiddle={true} size={10} className=' is-centered '>
                            {form()}
                        </ColumnAux>

                    </Box>
                </Modal.Content>
            </Modal>

        )
    }

    return modal()

}

export default CustomerSearchModal