import React from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Columns, Container, Section, Heading, Breadcrumb, Tabs, Image, Button, Modal, Hero } from 'react-bulma-components/dist';
import { getCurrentUser } from '../utilities/Store';
import ColumnAux from '../containers/ColumnAux'
// import homeBg1 from '../assets/homeBg1.png'
// import homeBg2 from '../assets/homeBg2.png'
import homeBg1 from '../assets/banner5.jpg'
import homeBg2 from '../assets/banner5.jpg'

// import PersonaLogo512x2 from '../assets/persona-logo512@2x.png'
// import ClientLogo512 from "../assets/clientLogo512.png"
import ClientLogo512 from '../assets/tw-logo-140x30.svg'

import { clientLogoUrl, clientName } from '../utilities/clientInfo';

// import homeBg1 from '../assets/PersonaLogo.png'


const Welcome = (props) => {



    let cu = getCurrentUser()


    // create new order

    // find orders

    // view Trials

    // mark delivery 

    // manage vendor window



    let features = () => {

        return (

            <Columns>
                <Columns.Column>
                    <p className="bd-notification is-success">First Column</p>
                </Columns.Column>
                <Columns.Column>
                    <p className="bd-notification is-info">Second Column</p>
                </Columns.Column>
                <Columns.Column>
                    <p className="bd-notification is-warning">Third Column</p>
                </Columns.Column>
                <Columns.Column>
                    <p className="bd-notification is-warning">Fourth Column</p>
                </Columns.Column>
            </Columns>


        )

    }

    const sectionStyle = {
        backgroundImage: `url(${homeBg1})`,
        backgroundPosition: 'top',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    };


    const sign = () => {

        let s = ' from Left.'

        if (window.innerWidth < 760) {
            s = ' from Top. '
        }

        return s
    }

    let welcomePagee = () => {
        return (
            <div>
                <Heading size={4} className='is-capitalized has-text-grey-light' > Hi&nbsp; {cu.name}. Start by choosing option from Menu. </Heading>
                <Image className='  is-4by3' src={homeBg2} alt="Brand Image" />
            </div>

        )
    }
    let welcomePage = () => {
        return (
            <Container>



                <section >
                    <ColumnAux size={8} isMiddle={true} >
                        <br />
                        <Heading size={4} className='is-capitalized  has-text-centered ' > Hi&nbsp; <span className='is-capitalized'> {cu.name}</span> </Heading>
                        <Heading subtitle size={6} className=' has-text-grey has-text-centered ' >   Welcome to {clientName}. <span className=''> Start by choosing from Menu. </span>  </Heading>

                        <Section className="marginTop40">
                            <ColumnAux size={8} isMiddle={true} >
                                <br />
                                <Image className='  ' src={clientLogoUrl} alt="Brand Image" />
                            </ColumnAux>
                        </Section>

                    </ColumnAux>

                </section>





                {/* <Section className='' style={sectionStyle}>
                        <Hero className=' is-fullheight  '>
                            <Hero.Body className=' '>
                                <Container className=''>
                                    <Columns className='is-centered  '>
                                    </Columns>
                                </Container>
                            </Hero.Body>
                        </Hero>
                    </Section> */}

                {/* <Columns>
                        <Columns.Column className="has-background-success" >
                            <p className="bd-notification is-success">First Column</p>
                        </Columns.Column>
                        <Columns.Column className="has-background-info" >
                            <p className="bd-notification is-info">Second Column</p>
                        </Columns.Column>
                        <Columns.Column className="has-background-warning" >
                            <p className="bd-notification is-warning">Third Column</p>
                        </Columns.Column>
                        <Columns.Column className="has-background-danger" >
                            <p className="bd-notification is-warning">Fourth Column</p>
                        </Columns.Column>
                    </Columns> */}

            </Container>
        )
    }


    return (
        <div>
            {welcomePage()}
        </div>
    )

}

export default Welcome