import React, { useState, useEffect } from 'react'
import MainMenu from '../components/Menu/MainMenu'
import SideMenu from '../components/Menu/SideMenu'
import Aux from './Aux'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Columns, Container, Section, Heading, Breadcrumb, Tabs, Image, Button, Modal } from 'react-bulma-components/dist';
import { pages, departments, screen, departmentForscreen, clientScreenTab, profilePages } from '../utilities/enums'
import ClientInfoForm from './ClientInfoForm'
import ClientMeasurementsInfo from './ClientMeasurementsInfo'
import ClientOrdersInfo from './ClientOrdersInfo'
import ProductsOfOrder from '../containers/ProductsOfOrder'
import ProductModal from '../components/Cards/ProductModal'
import OrderModal from '../components/Cards/OrderModal'
import Images from './Images'


import { Route, Switch, withRouter, Redirect } from 'react-router-dom'

// Department Windows 
import Client from '../Departments/Store/client'
import Welcome from '../Departments/welcome'
import Store from '../Departments/Store/store'
import Factory from '../Departments/Factory/factory'
import Vendors from '../Departments/Vendors/allVendors'
import Clients from '../Departments/Clients/clients'
import Stocks from '../Departments/Stocks/stocks'
import Reports from '../Departments/Reports/reports'
import Users from '../Departments/Users/users'
import Slides from '../Departments/Slides/slides'
import Settings from '../Departments/Settings/settings'



import PageHeader from './PageHeader'

import ColumnAux from './ColumnAux'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { Location, User, Position } from '../utilities/UserClasses'
import { saveLocations, getLocations, saveUsers, getUsers, savePositions, getPositions } from '../utilities/Store'
import { hasFactory } from '../utilities/clientInfo'



const Dashboard = (props) => {

  const [currentDept, setCurrentDept] = useState(departments.Store)
  const [currentScreen, setCurrentScreen] = useState(screen.NewOrder)
  const [orderSelected, setOrderSelected] = useState(null)
  const [productSelected, setProductSelected] = useState(null)
  const [selectedImage, setSelectedImage] = useState(false)
  const [newMeasurement, setNewMeasurement] = useState(false)
  const [newPosture, setNewPosture] = useState(false)
  const [newPattern, setNewPattern] = useState(false)
  const [newOrder, setNewOrder] = useState(false)
  const [newProduct, setNewProduct] = useState(false)


  const [crumbLinks, setCrumbLinks] = useState('')

  const [currentTab, setCurrentTab] = useState(clientScreenTab.info)

  let isNewOrderAndInfoPending = false

  const menuOrNavClickedTo = (screen) => {
    console.log('LinkClickedTo Called on Id -', screen)

    setCurrentScreen(screen)
    setCurrentDept(departmentForscreen(screen))

    // if (dep === departments.Store) {
    //     props.history.push('/store')
    // } else {
    //     props.history.push('/dashboard/' + pageId)
    // }
    // props.clicked(pageId)
    // props.pageChanged(dep)
  }


  // useEffect(() => {
  //   loadDataOnlyOnce();
  // }, []);

  useEffect(() => {

  }, [])


  const setNoSelected = () => {
    setOrderSelected(null)
    setProductSelected(null)
    setNewOrder(false)
    setNewPattern(false)
    setNewPosture(false)
    setNewProduct(false)
    setNewMeasurement(false)
    setSelectedImage(false)
  }


  let breadcrumbs = (
    <p className=' '>
      {/* <Button className='is-small is-text margin10' > Back </Button> */}
      <Breadcrumb
        separator='succeeds'
        size='small'
        className='breadcrumbMargin'
        items={[
          {
            name: 'New Order',
            url: '#1',
          },
        ]}
      />

    </p>

  )





  const changeTab = (tab) => {

    if (isNewOrderAndInfoPending) {
      return
    }

    setCurrentTab(tab)

  }

  let tabs = (
    <Tabs
      //   type={select('Tab type', {boxed: 'boxed', toggle: 'toggle', 'toggle-rounded': 'toggle-rounded' }, 'boxed')}
      fullwidth={true}
      align='centered'
      size='small'
      className="is-black marginTop10"
    >
      <Tabs.Tab active={currentTab === clientScreenTab.info} onClick={() => { changeTab(clientScreenTab.info) }} >  Profile </Tabs.Tab>
      <Tabs.Tab active={currentTab === clientScreenTab.measurements} onClick={() => { changeTab(clientScreenTab.measurements) }} >  Measurements </Tabs.Tab>
      <Tabs.Tab active={currentTab === clientScreenTab.orders} onClick={() => { changeTab(clientScreenTab.orders) }} >  Orders </Tabs.Tab>

    </Tabs>

  )


  const profileSection = () => {
    return (
      <ColumnAux isMiddle={true} size={5} >
        <ClientInfoForm />
      </ColumnAux>
    )
  }

  const measurementSection = () => {
    return (
      <ColumnAux isMiddle={true} size={7} >
        <ClientMeasurementsInfo newImageSelected={addImage} />
      </ColumnAux>
    )
  }

  const selectOrder = (orderId) => {
    setOrderSelected(orderId)
    setCurrentScreen(screen.ProductsOfOrder)
  }

  const selectProduct = (productId) => {
    setProductSelected(productId)
  }

  const productClosed = () => {
    console.log('MODAL CLOSED ')
    setProductSelected(null)
    setCurrentScreen(screen.ProductsOfOrder)
  }


  const addOrder = () => {
    setNewOrder(true)
  }

  const addProduct = () => {
    setNewProduct(true)
  }

  const addImage = () => {
    setSelectedImage(true)
  }

  const ordersSection = () => {
    return (
      <ColumnAux isMiddle={true} size={7} >
        <ClientOrdersInfo selectOrder={selectOrder} addOrder={addOrder} />
      </ColumnAux>
    )
  }



  const currentTabScreen = () => {
    switch (currentTab) {
      case clientScreenTab.measurements: return measurementSection();
      case clientScreenTab.orders: return ordersSection();
      default: return profileSection();

    }

  }


  const productsOfOrderView = () => {

  }

  const orderHeader = () => {
    return (
      <Columns>
        <Columns.Column size={1} className="is-narrow">
          <Image src="https://avatars.dicebear.com/api/avataaars/example.svg?options[top][]=shortHair&amp;options[accessoriesChance]=93" alt="John Doe" size={64} rounded />
        </Columns.Column>
        <Columns.Column >
          {tabs}
        </Columns.Column>
      </Columns>
    )
  }





  const thisPage = () => {
    if (orderSelected) {
      return <ProductsOfOrder selectProduct={selectProduct} addProduct={addProduct} />
    }

    return (
      <Aux>
        {orderHeader()}
        <br />
        {currentTabScreen()}
      </Aux>
    )

  }


  const crumbLinkClicked = () => {
    setNoSelected()
  }


  const oldSection = () => {

    return (
      <Section>
        {/* Internal Page here */}

        {/* <PageHeader crumbItems={['Dashboard', 'New Order']} mainClicked={crumbLinkClicked} /> */}


        {/* { (imageSelected === true) ?  <ImageGallery items={images}  lazyLoad={true}  startIndex={selectedImageIndex} onClose={toggleImageSelect} /> : null } */}

        {(selectedImage !== null) ? <Images selectedImage={selectedImage} closed={setNoSelected} /> : null}
        {(newOrder === true) ? <OrderModal closed={setNoSelected} /> : null}
        {(newProduct === true) ? <ProductModal isNewProduct={true} closed={setNoSelected} /> : null}
        {(productSelected !== null) ? <ProductModal productId={selectProduct} closed={productClosed} /> : null}

        {thisPage()}

        {/* {currentPage} */}
        {/* <Route exact path='/dashboard' component={Dashboard} />
              <Route path='/dashboard/purchases' component={Purchases} />
              <Route path='/dashboard/invoices' component={Invoices} />
              <Route path='/dashboard/payments' component={Payments} />
              <Route path='/dashboard/profile' component={Profile} />
              <Route path='/dashboard/compliance' component={Compliance} />
              <Route path='/dashboard/messages' component={Messages} /> */}
      </Section>
    )
  }

  const devBaseName = '/'
  const productionBaseName = `${process.env.PUBLIC_URL}/`

  function giveBaseName() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      // dev code
      console.log('Giving Base Name - ', devBaseName)
      return  devBaseName
    } else {
      // production code
      console.log('Giving Base Name - ', productionBaseName)
      return productionBaseName
    }
  }


  const crumbs = () => {
    console.log('Crumbs Called')
    return (
      <PageHeader crumbItems={['Dashboard', 'New Order']} mainClicked={crumbLinkClicked} />
    )
  }


  const logout = () => {
    props.logout()
  }


  const mainMenu = () => {
    return <MainMenu logout={logout} />
  }

  const sideMenu = () => {
    return <SideMenu />
  }


  const publicUrl = process.env.PUBLIC_URL

  const defaultView = () => {
    return (
      <Aux>
        {mainMenu()}
        <Columns className='is-marginless is-narrow-tablet'>
          {sideMenu()}
          <Columns.Column className=''>
            <Container>

              <Section>
                {/* Internal Page here */}

                {/* {crumbs()} */} 




                <Switch>


                  <Route exact  path= '/store/newOrder' component={Client} />
                  <Route path='/store' component={Store} />
                  { hasFactory ? <Route path='/factory' component={Factory} /> : null }
                  <Route path='/vendors' component={Vendors} />
                  <Route path='/clients' component={Clients} />
                  <Route path='/stocks' component={Stocks} />
                  <Route path='/reports' component={Reports} />
                  <Route path='/slides' component={Slides} />
                  <Route path='/settings' component={Settings} />
                  <Route path='/users' component={Users} />
                  <Route path='/' component={Welcome} />


                </Switch>


              </Section>

            </Container>

          </Columns.Column>
        </Columns>
      </Aux>
    )
  }

  return (
    defaultView()
  )

}

export default withRouter(Dashboard)