import React, { useState } from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Button, Columns } from 'react-bulma-components/dist';
import { Formik, Form, Field } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Parse from 'parse'
import { tryUserLogin, getAndSaveLocations } from '../utilities/Login'
import cogoToast from 'cogo-toast'
import { getLocations, getPositions } from '../utilities/Store';
import { hasFactory } from '../utilities/clientInfo';
import { LocationType } from '../utilities/UserClasses';

import packageJson from '../../package.json';


const SignIn = (props) => {


    let formValues = {
        username: '',
        password: ''
    }

    const allLocations = getLocations()


    const [selectedLoc, setSelectedLoc] = useState(allLocations ? allLocations[0] : null)



    const loginUser = (values, callback) => {
        // setTimeout(() => {
        //     callback({ success: true, message: 'User signed In succesfully. ' })
        // }, 800);

        console.log("Selected Location is")
        console.log(selectedLoc)

        let inLocation = selectedLoc

        if (inLocation === null) {
            if (allLocations.length > 0) {
                inLocation = allLocations[0]
            } else {
                cogoToast.warn('Please select a location');
                callback({ success: false, message: "Error: Location not selected" })
                return
            }
        }


        tryUserLogin(values.username, values.password, inLocation, (success, user, errMsg) => {
            if (success === true) {
                // console.log(user)
                callback({ success: true, message: 'User login successful ' })
            } else {
                cogoToast.error('Invalid Credentials');
                // console.log(errMsg)
                callback({ success: false, message: "Error: " + errMsg })
            }
        })

    }


    const validateEmail = (value) => {
        let error;
        if (!value) {
            error = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            error = 'Invalid email address';
        }
        return error;
    }


    const validateUsername = (value) => {
        let error;
        if (!value) {
            error = 'Required';
        } else if (value.split(' ').length !== 1) {
            error = 'Username Cannot have spaces';
        } else if (value.length < 3) {
            error = 'Username should have atleast 5 characters';
        }
        return error;
    }

    const validatePassword = (value) => {
        let error;
        if (!value) {
            error = 'Required';
        } else if (value.length < 3) {
            error = 'Password should have atleast 5 characters';
        }
        return error;
    }


    const locationWasChanged = (e) => {

        let val = e.target.value

        let matchLoc = allLocations.filter((thisLoc) => {
            return thisLoc.objectId === val
        })

        if (matchLoc) {
            if (matchLoc.length > 0) {
                setSelectedLoc(matchLoc[0])
            }
        }

    }


    const formDiv = (props) => {

        return (
            <div >
                <Formik
                    initialValues={formValues}


                    onSubmit={(data, { setSubmitting, resetForm }) => {
                        setSubmitting(true)
                        // Make Any Async Request
                        console.log('Sign IN Submitted : ', data)

                        loginUser(data, (response) => {
                            setSubmitting(false)
                            resetForm(true)

                            if (response.success === true) {
                                props.isSignedIn(data)
                            }
                        })
                    }}
                >
                    {({ values, isSubmitting, errors, touched, isValidating }) => (

                        <Form >
                            <p className="has-text-grey-light type50 has-text-centered">{packageJson.version}</p>
                            <br />

                            <div className='field'>
                                <label className="label is-size-7  marginTop10 ">Location</label>

                                <div className="control ">
                                    <div className='select is-fullwidth'>
                                        <select name='position' className='select has-text-grey type50' defaultValue={selectedLoc.objectId} onChange={locationWasChanged}>

                                            {
                                                allLocations.filter((thisLoc) => {
                                                    if (!hasFactory) {
                                                        return thisLoc.type !== LocationType.factory
                                                    } else {
                                                        return true
                                                    }
                                                }).map((loc, index) => {
                                                    return <option key={index} value={loc.objectId} > {loc.type + " · " + loc.name} </option>
                                                })
                                            }

                                        </select>
                                    </div>
                                </div>
                            </div>


                            <div className='field'>
                                {errors.username && touched.username && <div className="help is-pulled-right is-danger">{errors.username}</div>}
                                <label className="label is-size-7 marginTop10">Username</label>
                                <Field name='username' type='input' className='input ' validate={validateUsername} />
                            </div>
                            <div className='field'>
                                {errors.password && touched.password && <div className="help is-pulled-right is-danger">{errors.password}</div>}
                                <label className="label is-size-7 marginTop10">Password</label>
                                <Field name='password' type='password' className='input ' validate={validatePassword} />

                            </div>

                            {/* <div className='field signUpButton'>
                                <Button disabled={isSubmitting || isValidating} loading={isSubmitting} className='button is-link is-fullwidth marginTop10' type='submit'>Submit</Button>
                            </div> */}
                            <div className='has-text-centered marginTop40'>

                                <Button disabled={isSubmitting || isValidating} loading={isSubmitting} className=' marginTop20 is-radiusless  is-fullwidth is-centerd  is-black ' type='submit' > Sign In &nbsp; </Button>

                                {/* <Button disabled={isSubmitting || isValidating} loading={isSubmitting} className=' marginTop20 is-radiusless is-centerd  is-black ' type='submit' > Sign In &nbsp;
            <i className=" has-texh-weight-thin marginLeft10"> <FontAwesomeIcon className='padRight20' icon={faArrowRight} /></i> </Button> */}

                                <p>
                                    {/* <Button disabled={isSubmitting || isValidating} className='is-text no-decoration is-small  marginTop10 is-white has-text-grey-light ' onClick={() => { props.hasForgotPassword('forgotPassword') }} >Forgot Password</Button> */}

                                </p>

                            </div>

                            <br />


                            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}

                        </Form>

                    )}

                </Formik>
            </div>
        )
    }





    return (
        formDiv(props)
    )
}

export default SignIn