import imageCompression from 'browser-image-compression';




export const isValidDate = (d) => {
    let isValid = false
    if (Object.prototype.toString.call(d) === "[object Date]") {
        // it is a date
        if (isNaN(d.getTime())) {  // d.valueOf() could also work
            // date is not valid
        } else {
            // date is valid
            isValid = true
        }
    } else {
        // not a date
    }
    return isValid
}



export const fnGetFileNameFromContentDispostionHeader =  (header) => {


    console.log("Incoming header is")
    console.log(header)

    let contentDispostion = header.split('; ');
    const fileNameToken = `filename*=UTF-8''`;

    let fileName = 'downloaded.pdf';
    for (let thisValue of contentDispostion) {
        if (thisValue.trim().indexOf(fileNameToken) === 0) {
            fileName = decodeURIComponent(thisValue.trim().replace(fileNameToken, ''));
            break;
        }
    }

    return fileName;
};


export const provideCompressedFile = (imageFile, maxWidthOrHeight, maxSizeMB , callback) => {

    console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    var options = {
        maxSizeMB: maxSizeMB,
        maxWidthOrHeight: maxWidthOrHeight,
        useWebWorker: true
    }
    imageCompression(imageFile, options)
        .then(function (compressedFile) {
            console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
            console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
            callback(true, compressedFile)
        })
        .catch(function (error) {
            console.log('Error while compressing image' + error.message);
            callback(false, imageFile)
        });
}



function importAll(r) {
    return r.keys().map(r);
}

const allImages = importAll(require.context('../assets/', false, /\.(png|jpe?g|svg)$/));


const getImageNamed = (name) => {
    // console.log('ALL IMAGES = ', allImages)
    let images = allImages.filter(path => {
        return path.includes(name)
    })
    return images ? images[0] : null   // add a placeholder image here
}

export const imageNamed = (name) => {
    let img = getImageNamed(name)
    return img ?? getImageNamed('placeholder')
}





export const getStringInMiddleOf = (fullString, inMirrorTag) => {

    if (fullString.includes(inMirrorTag) === false){
        return ""
    }

    let innerString =  fullString.substring(fullString.lastIndexOf( inMirrorTag + ':') + inMirrorTag.length + 1, fullString.lastIndexOf(':' + inMirrorTag))

    // console.log( "OC_isAllowed:true:OC_isAllowed".substring("OC_isAllowed:true:OC_isAllowed".lastIndexOf( 'OC_isAllowed:') + 1, "OC_isAllowed:true:OC_isAllowed".lastIndexOf(':' + 'OC_isAllowed')) )


    return innerString
}


export const getMiddleBoolVal = (fullString, inMirrorTag) => {

    if (fullString.includes(inMirrorTag) === false){
        return ""
    }

    let innerString = getStringInMiddleOf(fullString, inMirrorTag)
  
    let boolVal = false

    if (innerString.toLowerCase() === 'true'){
        boolVal = true
    }

    return boolVal
}
  



export const addIntToString = ( text, fromInt, toInt, incrementBy) => {

    if (incrementBy === null){
        incrementBy = 1
    }

    var allStrings = []

    for (let i = fromInt; i <= toInt.length; i+ incrementBy) {
        allStrings.push(`${text}${i}`)
    }

    return allStrings
}


export const onlyOneWhitespaceInWords = (string) => {
    var newStr = string.replace(/  +/g, ' ');
    return newStr
}


export const dateToTimeString = (myDate) => {
    var month= []
    month[0]="Jan";
    month[1]="Feb";
    month[2]="Mar";
    month[3]="Apr";
    month[4]="May";
    month[5]="Jun";
    month[6]="Jul";
    month[7]="Aug";
    month[8]="Sep";
    month[9]="Oct";
    month[10]="Nov";
    month[11]="Dec";
    var hours = myDate.getHours();
    var minutes = myDate.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ampm;
    return  myDate.getDate()+" "+month[myDate.getMonth()]+" "+myDate.getFullYear()+"  "+strTime;
  }


  export const dateToDateString = (myDate) => {
    var month= []
    month[0]="Jan";
    month[1]="Feb";
    month[2]="Mar";
    month[3]="Apr";
    month[4]="May";
    month[5]="Jun";
    month[6]="Jul";
    month[7]="Aug";
    month[8]="Sep";
    month[9]="Oct";
    month[10]="Nov";
    month[11]="Dec";
    // var hours = myDate.getHours();
    // var minutes = myDate.getMinutes();
    // var ampm = hours >= 12 ? 'pm' : 'am';
    // hours = hours % 12;
    // hours = hours ? hours : 12;
    // minutes = minutes < 10 ? '0'+minutes : minutes;
    // var strTime = hours + ':' + minutes + ampm;
    return  myDate.getDate()+"."+month[myDate.getMonth()]+"."+myDate.getFullYear();
  }


export const getFormattedDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [month, day, year].join('/');
}


  export const dateToNumber = (t) => {
      if (t == null) { return null }
    let thisDate = new Date (t)
    const date = ('0' + thisDate.getDate()).slice(-2);
    const month = ('0' + (thisDate.getMonth() + 1)).slice(-2);
    const year = thisDate.getFullYear();
    const hours = ('0' + thisDate.getHours()).slice(-2);
    const minutes = ('0' + thisDate.getMinutes()).slice(-2);
    const seconds = ('0' + thisDate.getSeconds()).slice(-2);
    // const str = `${date}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
    const str = `${date}${month}`;
    return  Number(str)
  }


  export const stringComps = (str, size) => {
    if (str == null) return [];
    str = String(str);
    size = ~~size;
    return size > 0 ? str.match(new RegExp('.{1,' + size + '}', 'g')) : [str];
}



// Patterns
export const splitProductAndCodeFromPattern = (patternString) => {
    if (patternString.length > 0 && patternString.includes("-")) {
        let comps = patternString.split("-")
        if (comps[0] != null) {
             let productType = comps[0] 
                var code = ""
                if  (comps[1] != null) {
                    code = comps[1]
                }
                return (productType, code)
        }
    }
    
    return null
}

export const makeProductPatternFrom = (productType, code) => {
    let patternString = `${productType}-${code}`
    return patternString
}
