import React, { useState, useEffect } from 'react'
import SignIn from '../components/SignIn'
import SignUp from '../components/SignUp'
// import PersonaLogo from '../assets/persona-logo512.png'
// import PersonaLogo from '../assets/PersonaLogo.png'
// import ClientLogo from '../assets/clientLogo.png'
import ClientLogo from '../assets/tw-logo-140x30.svg'


// import banner5 from '../assets/banner5.jpg'
// import homeBg1 from '../assets/homeBg1.png'
// import homeBg1 from '../assets/clientBG.png'
import homeBg1 from '../assets/homeBg2.png'


import { withRouter, Redirect } from 'react-router-dom'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import Dashboard from './Dashboard'
import {
    Columns, Heading, Container, Section,
    Button, Navbar, Hero, Tabs,
    Box, Image, Table, Modal
} from 'react-bulma-components/dist'

import { imageNamed, PRODUCT_TYPE } from '../utilities/utils'
import { isCurrentUser, logoutCurrentUser, getAccessAssets, getAndSaveLocations } from '../utilities/Login'
import cogoToast from 'cogo-toast'
import LoadingAux from './LoadingAux'
import { clientLogoUrl } from '../utilities/clientInfo'


const Landing = (props) => {
    const [signUpComplete, setSignUpComplete] = useState(false);
    const [signInComplete, setSignInComplete] = useState(null);

    const [assetsFetched, setAssetsFetched] = useState(false)
    const [fetching, setFetching] = useState(false)


    useEffect(() => {

        getPreLoginAssets()
        const isCurrentUsr = isCurrentUser()
        setSignInComplete(isCurrentUsr)
        console.log('EXISTING USER = ', isCurrentUsr)
    }, []);




    const getPreLoginAssets = () => {
        setFetching(true)
        getAccessAssets((succ, errMsg) => {
            setFetching(false)
            if (succ) {
                setAssetsFetched(true)
            } else {
                cogoToast.error(errMsg + " Check internet and reload page.")
            }
        })
    }

    // const fetchLocsNow = () => {
    //     getAndSaveLocations((succ, locs, errMsg) => {
    //         if (succ){
    //             setFetchedLocations(locs)
    //         }else{
    //             cogoToast.error(errMsg)
    //         }
    //     })
    // }



    const makeSignUpComplete = (payload) => {
        console.log('Sign UP Happened With = ', payload)
        setSignUpComplete(true)
    }

    // const signUpComp = () => {
    //     return (
    //         <Columns.Column size={4} className='has-background-white pad30 '>
    //             <Button className='is-small  is-text is-pulled-right is-radiusless has-text-grey-light no-decoration' onClick={() => { this.changeWelcomeScreen(this.HOME_SCREEN.signIn) }} > or Sign In </Button>
    //             <img src={ClientLogo} alt="Tailorwise: Streamlined Bespoke Tailoring" width="120" height="30" onClick={() => { this.changeWelcomeScreen(this.HOME_SCREEN.welcome) }} />

    //             <br />
    //             <div >
    //                 <SignUp isSignedUp={makeSignUpComplete} errorOccured={makeSignUpComplete} />

    //             </div>
    //         </Columns.Column>
    //     )
    // }


    const makeSignInComplete = (payload) => {
        if (payload === 'forgotPassword') {
            console.log('HAS FOR FORGOTTEN PASSWORD - Take to that screen')
            return
        }
        console.log('Sign In Happened With = ')
        window.location.assign("/")

        setSignInComplete(true)
    }




    const signInComp = () => {
        return (
            <Columns.Column size={4} className='has-background-white pad20 '>

                <div className='has-text-centered'>
                    <img className='' src={clientLogoUrl} alt="Tailorwise: Streamlined Bespoke Tailoring" width="140" height="132" />
                </div>
                <div >
                    <SignIn isSignedIn={makeSignInComplete} hasForgotPassword={makeSignInComplete} />
                </div>
            </Columns.Column>

        )
    }


    const sectionStyle = {
        // backgroundImage: `url(${homeBg1})`,
        backgroundPosition: 'top',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    };

    const logout = () => {
        logoutCurrentUser()
        setSignInComplete(false)
    }


    const appPage = () => {

        return (
            <div>
                <Dashboard logout={logout} />
            </div>

        )

    }

    const landingPage = () => {
        return (
            <Section className='' style={sectionStyle}>
                <Hero className=' is-fullheight  '>
                    <Hero.Body className=' '>
                        <Container className=''>
                            <LoadingAux isLoading={fetching} loaderHeight={300} isMain={false} size='medium'>
                                <Columns className='is-centered  '>
                                    {assetsFetched ?
                                        signInComp() :
                                        <Button loading={fetching} disabled={fetching} className=" is-medium " onClick={getPreLoginAssets} > Retry </Button>
                                    }
                                </Columns>
                            </LoadingAux>

                        </Container>
                    </Hero.Body>
                </Hero>
            </Section>
        )
    }


    const showDecision = () => {
        if (signInComplete === null) {
            return <div></div>
        }

        if (signInComplete === true) {

            return appPage()
        }

        return landingPage()

    }

    return (
        <div className=''
            onContextMenu={(e) => { e.preventDefault() }}
            onDragStart={(e) => { e.preventDefault() }} >
            {/* { (signInComplete === false) ? <Redirect to="/" /> : null} */}

            {showDecision()}

        </div>
    )

}


export default withRouter(Landing)
