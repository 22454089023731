import React, { useState } from 'react'
import { Box, Button, Modal, Heading, Card, Image, Columns, Table } from 'react-bulma-components/dist';
import ColumnAux from '../../containers/ColumnAux'
// import ProductForm from '../../containers/ProductForm'
import { ProductFilter, DateRange, CountRange, QuantRange } from '../../utilities/Filters'
import { Status_options_NonFormik, type_options_NonFormik, SaleType, type, Stage, FromStoreSection, Stage_options_NonFormik, ProductType_options_NonFormik } from '../../utilities/enums'

import { getUsers, getCurrentLocation } from '../../utilities/Store';


import DatePicker from 'react-datepicker'


const ProductFilterModal = (props) => {

    let [filter, setFilter] = useState(props.filter ?? ProductFilter.init())

    const resetFilter = () => {
        setFilter(ProductFilter.init())
    }


    // type, SaleType, Stage, FromStoreSection, StyleNo, ChallanNo, trialDate, DeliveryDate, RetrialCount, 


    const styleNoChanged = (e) => {
        console.log('Style Changed = ' + e.target.value)
        let val = e.target.value
        let pr = ProductFilter.copyFrom(filter)
        pr.styleNo = val
        setFilter(pr)
    }



    const addedByUserChanged = (e) => {
        console.log('Order AddedBy User  Changed = ' + e.target.value)

        if (e.target.value == null || e.target.value === '') {
            let pr = ProductFilter.copyFrom(filter)
            pr.addedByUser = null
            setFilter(pr)
            return
        }

        let allUsers = getUsers()
        let thisUs = allUsers.filter((us) => {
            return us.objectId === e.target.value
        })

        if (thisUs != null && thisUs.length) {
            let usr = thisUs[0]
            console.log('Changing Added By User to ' + usr.name)
            let pr = ProductFilter.copyFrom(filter)
            pr.addedByUser = usr
            setFilter(pr)
        }

    }


    const stageChanged = (e) => {
        console.log('Stage Changed = ' + e.target.value)
        let val = e.target.value
        let pr = ProductFilter.copyFrom(filter)
        pr.stage = val
        setFilter(pr)
    }

    const statusChanged = (e) => {
        console.log('Status Changed = ' + e.target.value)
        let val = e.target.value
        let pr = ProductFilter.copyFrom(filter)
        pr.status = val
        setFilter(pr)
    }

    const typeChanged = (e) => {
        console.log('Product Type Changed = ' + e.target.value)
        let val = e.target.value
        let pr = ProductFilter.copyFrom(filter)
        pr.type = val
        setFilter(pr)
    }



    const retialFromChanged = (e) => {
        console.log('Retrial From Changed = ' + e.target.value)
        let val = e.target.value
        let pr = ProductFilter.copyFrom(filter)
        let cr = new CountRange(val, pr.retrial ? (pr.retrial.to ?? null) : null)
        pr.retrial = cr
        setFilter(pr)
    }



    const delivery_from_Changed = (date) => {
        console.log('Delivery From Changed = ' + date)
        let pr = ProductFilter.copyFrom(filter)
        let dr = new DateRange(date, pr.deliveryDate ? (pr.deliveryDate.to ?? null) : null)
        pr.deliveryDate = dr
        setFilter(pr)
        console.log(date)
    }


    const delivery_to_Changed = (date) => {
        console.log('Delivery TO Changed = ' + date)
        let pr = ProductFilter.copyFrom(filter)
        let dr = new DateRange(pr.deliveryDate ? (pr.deliveryDate.from ?? null) : null, date)
        pr.deliveryDate = dr
        setFilter(pr)
        console.log(date)
    }


    const trial_from_Changed = (date) => {
        console.log('Trial From Changed = ' + date)
        let pr = ProductFilter.copyFrom(filter)
        let dr = new DateRange(date, pr.trialDate ? (pr.trialDate.to ?? null) : null)
        pr.trialDate = dr
        setFilter(pr)
        console.log(date)
    }


    const trial_to_Changed = (date) => {
        console.log('Trial TO Changed = ' + date)
        let pr = ProductFilter.copyFrom(filter)
        let dr = new DateRange(pr.trialDate ? (pr.trialDate.from ?? null) : null, date)
        pr.trialDate = dr
        setFilter(pr)
        console.log(date)
    }



    const saveFilter = () => {
        filter.isActive()
        props.filterSaved(filter)
    }


    const fields = () => {



        let allUsers = getUsers()
        allUsers.unshift(null)


        console.log('RENDERING FIELDS')

        return (
            <div>

                {/* <Columns className='is-mobile'>
                    <Columns.Column size={3}>
                        <label className="label type55  marginTop10 ">Status:</label>
                    </Columns.Column>
                    <Columns.Column>

                        <div className="control is-fullwidth">
                            <span className="select has-text-weight-semibold type65 is-fullwidth">
                                {Status_options_NonFormik('type', '  ', statusChanged, filter.status)}
                            </span>
                        </div>

                    </Columns.Column>
                </Columns> */}

                <Columns className='is-mobile'>
                    <Columns.Column size={3}>
                        <label className="label type55  marginTop10 ">Type:</label>
                    </Columns.Column>
                    <Columns.Column>

                        <div className="control is-fullwidth">
                            <span className="select has-text-weight-semibold type65 is-fullwidth">
                                {ProductType_options_NonFormik('type', '  ', typeChanged, filter.type, true)}
                            </span>
                        </div>

                    </Columns.Column>
                </Columns>


                {
                    getCurrentLocation().isMain ?
                        <Columns className='is-mobile'>
                            <Columns.Column size={3}>
                                <label className="label type55  marginTop10 ">Added By User:</label>
                            </Columns.Column>
                            <Columns.Column>

                                <div className="control ">
                                    <div className='select type65 is-fullwidth'>
                                        <select name='user' className='select ' defaultValue={filter.addedByUser ? filter.addedByUser.objectId : ''} onChange={addedByUserChanged}>
                                            {
                                                allUsers.map((us, index) => {
                                                    return <option key={index} value={(us != null) ? us.objectId : ''} > {(us != null) ? us.name : 'None'} </option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                            </Columns.Column>
                        </Columns>
                        :
                        null
                }




                <Columns className='is-mobile'>
                    <Columns.Column size={3}>
                        <label className="label type55  marginTop10 ">Stage:</label>
                    </Columns.Column>
                    <Columns.Column>

                        <div className="control is-fullwidth">
                            <span className="select has-text-weight-semibold type65 is-fullwidth">
                                {Stage_options_NonFormik('type', '  ', stageChanged, filter.stage)}
                            </span>
                        </div>

                    </Columns.Column>
                </Columns>


                <Columns className='is-mobile'>
                    <Columns.Column size={3}>
                        <label className="label type55  marginTop10 ">Style No:</label>
                    </Columns.Column>
                    <Columns.Column>

                        <div className="control is-fullwidth">
                            <div className='field '>
                                <input name='styleNo' defaultValue={filter.styleNo} placeholder='Style No.' type='input' className='input field has-text-weight-semibold type65' onChange={e => { styleNoChanged(e) }} />
                            </div>
                        </div>

                    </Columns.Column>
                </Columns>


                <Columns className='is-mobile'>
                    <Columns.Column size={3}>
                        <label className="label type55  marginTop10 ">Retrial Count:</label>
                    </Columns.Column>
                    <Columns.Column>

                        <div className="control is-fullwidth">
                            <div className='field '>
                                <input name='styleNo' defaultValue={filter.retrial ? (filter.retrial.from ?? null) : null} placeholder='More Than' type='number' min={0} max={10} className='input field has-text-weight-semibold type65' onChange={e => { retialFromChanged(e) }} />
                            </div>
                        </div>

                    </Columns.Column>
                </Columns>

                <hr />

                <Columns className='is-mobile'>
                    <Columns.Column size={3}>
                        <label className="label type55  marginTop10 ">Trial After:</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className="control is-fullwidth is-expanded">
                            <DatePicker name='trialAfter' isClearable onFocus={e => e.target.blur()} dateFormat="dd/MMM/yyyy" className='datePicker has-text-weight-semibold type65 is-fullwidth  input' placeholderText='After Date' selected={filter.trialDate ? (filter.trialDate.from ?? null) : null} onChange={date => trial_from_Changed(date)} />
                        </div>
                    </Columns.Column>
                </Columns>


                <Columns className='is-mobile'>
                    <Columns.Column size={3}>
                        <label className="label type55  marginTop10 ">Trial Before:</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className="control is-fullwidth is-expanded">
                            <DatePicker name='trialBefore' isClearable onFocus={e => e.target.blur()} dateFormat="dd/MMM/yyyy" className='datePicker has-text-weight-semibold type65 is-fullwidth  input' placeholderText='Before Date' selected={filter.trialDate ? (filter.trialDate.to ?? null) : null} onChange={date => trial_to_Changed(date)} />
                        </div>
                    </Columns.Column>
                </Columns>


                <hr />

                <Columns className='is-mobile'>
                    <Columns.Column size={3}>
                        <label className="label type55  marginTop10 ">Delivery After:</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className="control is-fullwidth is-expanded">
                            <DatePicker name='deliveryAfter' isClearable onFocus={e => e.target.blur()} dateFormat="dd/MMM/yyyy" className='datePicker has-text-weight-semibold type65 is-fullwidth  input' placeholderText='After Date' selected={filter.deliveryDate ? (filter.deliveryDate.from ?? null) : null} onChange={date => delivery_from_Changed(date)} />
                        </div>
                    </Columns.Column>
                </Columns>


                <Columns className='is-mobile'>
                    <Columns.Column size={3}>
                        <label className="label type55  marginTop10 ">Delivery Before:</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className="control is-fullwidth is-expanded">
                            <DatePicker name='deliveryBefore' isClearable onFocus={e => e.target.blur()} dateFormat="dd/MMM/yyyy" className='datePicker has-text-weight-semibold type65 is-fullwidth  input' placeholderText='Before Date' selected={filter.deliveryDate ? (filter.deliveryDate.to ?? null) : null} onChange={date => delivery_to_Changed(date)} />
                        </div>
                    </Columns.Column>
                </Columns>


                <hr />

                <br />

                <div className='is-centered has-text-centered marginBottom20'>
                    <Button className=' is-centered is-text has-text-centered' onClick={resetFilter} > Reset </Button>
                </div>
                <br />
                <Button className='is-fullwidth is-black' onClick={saveFilter} > Save </Button>

            </div>
        )

    }



    const modal = () => {
        return (
            <div>

                <Modal show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={props.closed} >
                    <Modal.Content className='is-padingless'>
                        <Box className='is-radiusless is-padingless fadeIn has-background-light'>
                            <Heading key='3' size={6} className='is-size-5-mobile has-text-black has-text-semibold has-text-centered' > Search Conditions </Heading>
                            <br />
                            <ColumnAux isMiddle={true} size={10} className=' is-centered '>
                                {fields()}
                            </ColumnAux>

                        </Box>
                    </Modal.Content>
                </Modal>
            </div>
        )
    }

    return modal()

}

export default ProductFilterModal