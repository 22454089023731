import React, { useEffect, useState } from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Box, Button, Modal, Heading, Table, Dropdown, Tag } from 'react-bulma-components/dist';
import { FitPreference, MeasurementType } from '../utilities/enums'
import ColumnAux from './ColumnAux';

import { Measurement, MeasurementTemplate } from '../utilities/Classes'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPrint, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Field } from 'formik';
import cogoToast from 'cogo-toast';
import MeasureEditModal from './MeasureEditModal'
import MeasurementTemplates from './MeasurementTemplates';
import LoadingAux from './LoadingAux';
import ChoiceModal from '../components/Cards/ChoiceModal';

const MeasurementModal = (props) => {


    const setUpMeasurement = () => {
        if (props.measurement) {

            return props.measurement
        } else {
            // When we create or edit Template
            return new MeasurementTemplate(props.type, '', [], null)
        }
    }


    let [measurement, setMeasurement] = useState(setUpMeasurement())
    let [isUploading, setiSUploading] = useState(false)
    let [selectedMeasure, setSelectedMeasure] = useState(null)
    let [addMeasure, setAddMeasure] = useState(false)
    let [loading, setLoading] = useState(false)
    let [custMeasures, setCustMeasures] = useState(null)

    let [askACfromExistingFit, setAskACfromExistingFit] = useState(false)
    let [askFitOpts, setAskFitOpts] = useState(false)
    let [chosenFit, setChosenFit] = useState(null)


    // {(useMeasurementFromTemp !== null) ? <MeasurementModal purpose='useTemplate' template={measurementSelected}  type={MeasurementType.body} closed={setNoSelected} templateUsed={custMeasurementAdded} /> : null}

    // {(viewProdMesurement !== null) ? <MeasurementModal purpose='edit' measurement={viewProdMesurement} type={MeasurementType.product} closed={closeModals} measurementUpadted={prodMeasurmentUpdated} /> : null}


    useEffect(() => {

        console.log('INCOMING PRODUCT')
        if (props.product) {
            console.log(props.product)
        }

        if (props.purpose === 'useTemplate' && props.type === MeasurementType.product) {
            initAutoMeasures(false)
        }

    }, [])


    const initAutoMeasures = (triggeredManual) => {

        console.log("Measurement Measures Auto")
        console.log(measurement.measures.map((ms) => {
            ms.toString()
        }))

        let autoMeasures = measurement.measures.filter((ms) => {
            return ms.isAuto === true
        })


        // if there are no auto measures. no need to create.
        if (!autoMeasures.length || autoMeasures.length === 0) {

            if (triggeredManual) {
                cogoToast.warn("There are no measures with AutoCalculate rule. Edit or create one .")
            }

            console.log("There are no auto meausures")
            return
        }

        if (!props.product) {
            cogoToast.warn("Could not find product to check its customer measurements for AutoCreate.")
            return
        }

        let cust = props.product.customer
        if (cust === null || cust === undefined) {
            cogoToast.warn("Could not find customer to check customer measurements for Auto Calculations.")
            return
        }

        let allCustMeasures = cust.conciergeMeasurements ?? []

        // Add Customer Concierge Measurements first. They can also be used for AutoCreate

        console.log("CUST MEASURES NOW ARE")
        console.log(custMeasures)


        console.log("AC ")
        if (custMeasures !== null) {
            // directly start calculating
            allCustMeasures = custMeasures
            console.log("AC ONE")
            autoMeasureAskPreferenceAndBegin(allCustMeasures)
        } else {
            setLoading(true)
            Measurement.getAll(MeasurementType.body, cust, null, (succ, measurementsArr, errMsg) => {
                setLoading(false)
                if (succ) {

                    console.log("AC FETCHED MEASUREMENTS")
                    console.log(measurementsArr)

                    measurementsArr.map((thisMeasurement) => {
                        allCustMeasures = allCustMeasures.length ? allCustMeasures.concat(thisMeasurement.measures) : thisMeasurement.measures
                    })

                    console.log("AC ALL MEASUREMENTS")
                    console.log(allCustMeasures)

                    setCustMeasures(allCustMeasures)
                    // Start auto measure
                    autoMeasureAskPreferenceAndBegin(allCustMeasures)

                } else {
                    cogoToast.warn("Could not load customer measurements for Auto Calculations.")
                }
            })
        }





    }

    const autoMeasureAskPreferenceAndBegin = (allCustMeasures) => {

        console.log("Incoming Cust Measures")
        console.log(allCustMeasures)

        let autoMeasures = measurement.measures.filter((ms) => {
            return ms.isAuto === true
        })

        var fitPrefExists = false
        var fitPrefVal = FitPreference.regular

        // CHECK IF FIT PREFRENCE IS ALREADY THERE...

        if (props.product !== null) {
            let ss = props.product.styleSelections

            console.log("SS is")
            console.log(ss)

            if (ss){
                if (ss !== null) {
                    if (ss.length) {
                        ss.map((thisSs) => {
                            let keyOfThisSS = thisSs.key ?? ""
                            if (keyOfThisSS.toLowerCase() === "fit" || keyOfThisSS.toLowerCase() === "fit preference") {
                                let fitValue = thisSs.value ?? ""
                                if (fitValue !== "" && fitValue !== null) {
                                    // CHECK IF THE VALUE MATCHES WITH OUR ENUM VALUES
                                    if (fitValue.toUpperCase() === FitPreference.slim.toUpperCase() || fitValue.toUpperCase() === FitPreference.regular.toUpperCase() || fitValue.toUpperCase() === FitPreference.comfort.toUpperCase()) {
    
                                        setChosenFit(fitValue)
                                        console.log("FIT VALUE IS = ", fitValue)
    
                                        fitPrefVal = fitValue
                                        fitPrefExists = true
                                    }
                                }
                            }
                        })
                    }
                }
            }


        }



        if (autoMeasures.length) {
            setLoading(true)



            console.log("AC TWO")

            if (fitPrefExists) {

                console.log("AC THREE")



                setAskACfromExistingFit(true)

                // let isYes = true
                // let isLetMeChoose = false
                // if (window.confirm("Something to confirm")){
                //     alert("Cofirm Succeeded")
                // }else{
                //     alert("Confirm did not succeed")
                // }
                // CONFIRM Alert Here
                // alert(`Auto calculate with ${fitPrefVal} Fit ?\n\n ${fitPrefVal} is already selected as Fit-Preference in Style Selections of this product. Do you want to Auto-calculate with \(fitPrefVal) Auto-calculate values ?`)

                // Show Confirm Alert

                // If yes

                // If no





                // if (confirm(`Auto calculate with ${fitPrefVal} Fit ?\n\n ${fitPrefVal} is already selected as Fit-Preference in Style Selections of this product. Do you want to Auto-calculate with \(fitPrefVal) Auto-calculate values ?`)) {
                //     // txt = "Yes";

                // } else {
                //     // txt = "Let me Choose";
                // }

                // LETS JUST PRESSURE, YES IS PRESSED 

                // if (isYes) {
                //     autoCreateMeasuresWith(fitPrefVal, allCustMeasures)

                // } else {
                //     autoCreateWithAfterChoice(allCustMeasures)

                // }

            } else {

                console.log("AC FOUR")

                setAskFitOpts(true)
                // autoCreateWithAfterChoice(allCustMeasures)

            }
        } else {
            setLoading(false)

            console.log("AC FIVE")

            cogoToast.warn("There are no measures with AutoCalculate rule. Edit or create one .")
            return
        }

    }




    const autoCreateWithAfterChoice = (allCustMeasures) => {

        // CONFIRM BOX
        // Ask for Fit Preference
        let askTitle = "Fit for AutoCalculate"
        let askSubtitle = "Select a fit preference for auto-calculate"
        let askOptions = [FitPreference.slim, FitPreference.regular, FitPreference.comfort, "Create Manual Only"]



        // CONFIRM Alert Here
        alert(`Choose your preferred fit. [OPTIONS]`)
        // Know the chosen answer

        // When choice is selected

        // If Slim 

        // If Regular

        // If Comfort

        // After Selection
        let chosenFit = FitPreference.slim

        if (chosenFit !== "Create Manual Only") {
            // 

        }

        autoCreateMeasuresWith(chosenFit, custMeasures)

    }


    const autoCreateMeasuresWith = (fitPreference, allCustMeasures) => {

        // CHANGE ALL AUTO MEASURES

        let allMeasurementsCopy = { ...measurement }
        var isAnyChanged = false

        allMeasurementsCopy.measures.map((thisM) => {

            let anyErr = false
            if (thisM.isAuto === true) {

                console.log("AC -- isAUTO named = ", thisM.name)


                if (thisM.autoFromMeasureName !== "" || thisM.autoFromMeasureName !== null) {

                    console.log("AC -- isAUTO AUTO-MEASURE-NAME = ", thisM.autoFromMeasureName)


                    let bodyMeasureName = thisM.autoFromMeasureName

                    let regVal = thisM.autoRegularAddVal ?? 0.0
                    let slimVal = thisM.autoSlimAddVal ?? 0.0
                    let comfortVal = thisM.autoComfortAddVal ?? 0.0

                    var chosenVal = regVal

                    switch (fitPreference) {
                        case FitPreference.slim:
                            chosenVal = slimVal
                            break;
                        case FitPreference.comfort:
                            chosenVal = comfortVal
                            break;
                        default:
                            chosenVal = regVal
                    }


                    console.log("AC -- FINDING = ", thisM.autoFromMeasureName)
                    console.log("IN = ")
                    console.log(allCustMeasures)


                    let match = allCustMeasures.filter((thisM) => {
                        // console.log("thisM is")
                        // console.log(thisM)
                        console.log(`matching ${thisM.name} with ${bodyMeasureName}`)

                        return thisM.name === bodyMeasureName
                    })

                    console.log("AC MATCH IS = ")
                    console.log(allCustMeasures)


                    if (match !== null && match.length) {
                        if (match.length > 0) {
                            let matchingFirst = match[0]

                            let finalVal = (Number(matchingFirst.value) ?? Number(matchingFirst.defaultValue)) + Number(chosenVal)
                            console.log(`ADDING  mfVal=${matchingFirst.value} mfDefVal=${matchingFirst.defaultValue} plus ChosenVal=${chosenVal}. FINAL VAL = ${Number(finalVal).toFixed(2)}`)

                            thisM.value = Number(finalVal).toFixed(2)
                            thisM.defaultValue = Number(finalVal).toFixed(2)
                            // term it autoCreatedSuccess or not
                            thisM.isAutoCreateSuccess = true

                            isAnyChanged = true

                        } else {
                            anyErr = true
                        }
                    } else {
                        anyErr = true
                    }

                } else {
                    anyErr = true
                }

            } else {
                // none
            }


            if (anyErr) {
                thisM.isAutoCreateSuccess = false
            }

        })




        if (isAnyChanged) {

            console.log("AC NINE = ")


            // Disclaimer alert

            // CONFIRM BOX
            let daTitle = "Check Measurements"
            let daMessage = "You are suggested to have final measurements check yourself.  \n\n 🟩 - Successfully changed measurements are green. \n\n 🟥 - UnSuccessful as associated body measurement was not found. Are in red. \n\n • Non auto-calculted measures remain same."

            let conciergeMsgTitle = "\n\n\n· Client Concierge Current Measurements are preferred in auto calculation."

            let cust = props.product.customer
            let conciergeMeasurements = cust.conciergeMeasurements ?? []
            if (conciergeMeasurements.length) {
                daMessage += conciergeMsgTitle
            }

            setTimeout(() => {
                alert(daMessage)
            }, 150)



            console.log("AC SETTING MEAUSURES IS = ")
            console.log(allMeasurementsCopy)
            setMeasurement(allMeasurementsCopy)


        } else {
            // Show other error alert if necessary 

            console.log("AC TEN = ")

            // CONFIRM BOX
            alert("No measurements were changed. No BodyMeasure name matched with Auto-Calculation rules.")

        }
        setLoading(false)
    }


    const closed = () => {
        setSelectedMeasure(null)
        setAddMeasure(false)
    }


    const createUpdateTemplate = () => {
        // cogoToast.loading('Creating / Updating template')

        if (measurement.name == null || measurement.name === '') {
            cogoToast.warn('Add Measurement Name')
            return
        }

        if (measurement.measures == null || measurement.measures === []) {
            cogoToast.warn('Add atleast 1 Measure to save')
            return
        }

        if (measurement.measures.length < 1) {
            cogoToast.warn('Add atleast 1 Measure to save')
            return
        }


        if (measurement.objectId != null && measurement.objectId !== '') {
            // update

            let tmp = MeasurementTemplate.copyFrom(measurement)
            // let tmp = new MeasurementTemplate(null)

            console.log('UPDATING TEMPLATE')
            console.log(tmp)

            setiSUploading(true)
            tmp.update((succ, msg) => {
                setiSUploading(false)
                if (succ) {
                    // console.log('MEASUREMENT UPADTED')
                    props.templateSaved(tmp)
                    props.closed()
                } else {
                    cogoToast.error('Could not update Measurement Template. Check internet and try again.')
                    // alert(msg)
                }
            })



        } else {
            // add



            let tmp = MeasurementTemplate.copyFrom(measurement)
            // let tmp = new MeasurementTemplate(null)

            console.log('ADDING TEMPLATE')
            console.log(tmp)

            setiSUploading(true)
            tmp.add((succ, msg) => {
                setiSUploading(false)
                if (succ) {
                    // console.log('MEASUREMENT UPADTED')
                    props.templateSaved(tmp)
                    props.closed()
                } else {
                    cogoToast.error('Could not add Measurement Template. Check internet and try again.')
                    // alert(msg)
                }
            })


            // return new MeasurementTemplate(props.type, '', [], null)
        }

    }

    const deletePressed = () => {


        if (measurement.type == MeasurementType.conciergeOrder) {
            cogoToast.warn('Concierge Order Measurements cannot be deleted')
            return
        }

        if (measurement.type == MeasurementType.conciergeBody) {
            cogoToast.warn('Customer Concierge Body Measurements cannot be deleted')
            return
        }

        if (measurement.objectId == null) {
            cogoToast.error('No Measurement Id found to delete. Report error.')
            return
        }


        let tmp = null

        if (props.isTemplateMode) {
            tmp = MeasurementTemplate.copyFrom(measurement)

        } else {
            tmp = Measurement.copyFrom(measurement)
        }


        setiSUploading(true)
        tmp.delete((succ, msg) => {
            setiSUploading(false)
            if (succ) {
                // console.log('MEASUREMENT UPADTED')
                if (props.isTemplateMode) {
                    props.templateRemoved(tmp)
                } else {
                    props.measurementRemoved(tmp)

                }
                props.closed()
            } else {
                cogoToast.error('Could not delete Measurement Template. Check internet and try again.')
                // alert(msg)
            }
        })


    }

    const inputClicked = (index, e, val) => {

    }


    const updateMeasures = () => {



        if (props.isTemplateMode) {
            createUpdateTemplate()
            return
        }

        // props.measurement.update(  )

        console.log('SAVING MEASUREMENT = ')
        console.log(measurement)


        setiSUploading(true)

        let ms = new Measurement(measurement.type, measurement.name, measurement.customer, measurement.product, measurement.measures, measurement.objectId)
        ms.isConciergeMeasurement = measurement.isConciergeMeasurement

        if (ms.isConciergeMeasurement) {
            // Update this in customer 
            setiSUploading(false)
            props.measurementUpadted(ms)
            return
        }


        if (props.purpose === 'useTemplate') {

            ms.objectId = null

            ms.add((succ, msg) => {
                setiSUploading(false)
                if (succ) {
                    // console.log('MEASUREMENT ADDED FROM TEMPLATE')
                    // console.log(ms)
                    props.templateUsed(ms)
                } else {
                    cogoToast.error('Could not add Measurement. Check internet and try again.')
                    // alert(msg)
                }
            })


        } else {



            ms.update((succ, msg) => {
                setiSUploading(false)
                if (succ) {
                    // console.log('MEASUREMENT UPADTED')
                    props.measurementUpadted(ms)
                } else {
                    cogoToast.error('Could not update Measurement. Check internet and try again. 1')
                    // alert(msg)
                }
            })

        }



    }



    const measurementNameChanged = (e) => {
        let value = e.target.value
        // console.log('Measure name changed to ' + value)
        let mss = { ...measurement }
        mss.name = value
        setMeasurement(mss)
    }

    const measureChanged = (index, evt) => {
        let value = evt.target.value


        // var regExp = /[a-zA-Z]/g;
        // if(/[a-z]/i.test(value)){
        //     cogoToast.warn("Only numbers can be saved")
        // }

        // const reg = /^[0-9\b]+$/;

        // const isnum = reg.test(evt.target.value);
        // if (isnum) {
        // console.log('Measure was changed at index ' + index + ' with value ' + value)
        let mss = { ...measurement }
        let measures = [...mss.measures]
        measures[index].value = value
        mss.measures = measures
        setMeasurement(mss)
        // }else{
        //     evt.preventDefault()
        //     cogoToast.warn("Only numbers allowed")
        // }
    }


    const rowEditPressed = (index) => {
        console.log('Edit row pressed on index = ' + index)

        let thisMeasure = { ...measurement.measures[index] }
        let nm = {
            'measure': thisMeasure,
            'index': index
        }
        setSelectedMeasure(nm)

    }

    const addMeasurePressed = () => {
        console.log('Add row pressed on index ')
        setAddMeasure(true)

    }





    const dropdownValChanged = (val) => {
        if (val === "autoCreate") {
            // alert("Auto Create Pressed")

            initAutoMeasures(true)
        }

        if (val === "delete") {
            deletePressed()
        }
    }


    const modal = () => {
        return (
            <Modal show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={() => props.closed()} >
                <Modal.Content className='is-padingless has-background-light'>
                    {props.type === MeasurementType.product && props.product ?

                        <Dropdown className=" is-arrowless is-grey noDropdownIcon" color="light" label='•••' onChange={(val) => { dropdownValChanged(val) }} >
                            <Dropdown.Item value="autoCreate" >
                                Auto Calculate
                        </Dropdown.Item>
                            {/* {measurement.objectId && props.isTemplateMode ?  */}
                            {/* <div> */}
                            <Dropdown.Divider />
                            <Dropdown.Item value="delete">
                                Delete
                        </Dropdown.Item>
                            {/* </div> : null} */}
                        </Dropdown>

                        :
                        <Dropdown className=" is-arrowless is-grey noDropdownIcon" color="light" label='•••' onChange={(val) => { dropdownValChanged(val) }} >
                            <Dropdown.Item value="delete">
                                Delete
                        </Dropdown.Item>
                            {/* </div> : null} */}
                        </Dropdown>
                    }
                    <Box className='is-radiusless is-padingless no-border is-shadowless has-background-transparent '>

                        <Heading size={6} className='is-size-5-mobile has-text-black has-text-semibold has-text-centered' > Measurement </Heading>

                        <LoadingAux isMain={true} loaderHeight={400} isLoading={loading}>

                            <ColumnAux isMiddle={true} size={10} className=' is-centered '>
                                {/* <div className='type70 has-text-weight-semibold meginBottom10 is-full'> {measurement.name} </div> */}
                                <input name='measurementName' defaultValue={measurement.name} onChange={measurementNameChanged} type='text' className='field input has-text-weight-semibold is-fullwidth  ' placeholder='Measurement Name' />

                                <br />
                                <Table className='is-hoverable is-size-7'>
                                    <thead>
                                        <tr>
                                            <th className='type60' > Name </th>
                                            <th className='type60 has-text-right' >  {props.isToSelectMeausre ? "SELECT" : (props.purpose === 'useTemplate' ? "Default Value" : "Value")}  </th>
                                            <th className='type60' > Unit </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            measurement.measures.map((ms, index) => {
                                                return <tr key={index} className='mtMargin'>
                                                    <td className='type60 '>
                                                        {(props.purpose === 'useTemplate') ?
                                                            null :
                                                            <Button className="type74  has-text-grey-light  no-decoration is-text is-small" onClick={() => { rowEditPressed(index) }} > <FontAwesomeIcon className='' icon={faEdit} /></Button>
                                                        }
                                                        {/* { (ms.isAutoCreateSuccess === true ? " 🟩  " : "") + (ms.isAutoCreateSuccess === false ? " 🟥  " : "")  + ms.name}</td> */}
                                                        <span className={ms.isAutoCreateSuccess !== null ? (ms.isAutoCreateSuccess == true ? "has-text-success" : "has-text-danger") : ""}>{ms.name}</span>  </td>

                                                    <td className='has-text-right type60 '>



                                                        {
                                                            props.isToSelectMeausre ?
                                                                <Button className="is-black is-small" onClick={() => { props.measureIsSelected(ms) }}> Select </Button>
                                                                : (
                                                                    props.isTemplateMode ?
                                                                        <span className='marginLeft10 '>{ms.defaultValue}</span>

                                                                        :
                                                                        <input name={'measure' + index} value={(ms.value === 0.0 || ms.value === 0 || ms.value === "0.00" || ms.value === "0" || ms.value === null || ms.value === "") ? "" : ms.value} onChange={(e) => { measureChanged(index, e) }} type='number' min={0} max={100} className='field input has-text-weight-semibold type65 width100 ' placeholder='' />
                                                                    // <input name={index} value={(ms.value === 0.0 || ms.value === 0.00 || ms.value === 0 || ms.value === "0.00" || ms.value === "0" || ms.value === null || ms.value === undefined || ms.value === "" || !ms.value) ? "" : ms.value} onChange={(e) => {    measureChanged(index, e) }} type='number'  className='field input has-text-weight-semibold type65 width100 ' placeholder=''  />

                                                                )

                                                        }
                                                    </td>
                                                    <td>
                                                        <span className='marginLeft10 width80'>{props.isToSelectMeausre ? null : ms.unit}</span>
                                                    </td>

                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </Table>
                                {/* {props.isTemplateMode ? <div className='is-centered has-text-centered is-rounded'> <Button className='   is-small has-text-bold' onClick={addMeasurePressed}> ADD + </Button> </div> : null} */}
                                {
                                    props.purpose === 'useTemplate' ?
                                        <p className="has-text-centered type55 has-text-grey"> Can be edited after Use is pressed </p>
                                        :

                                        // (measurement.isConciergeMeasurement ? null :
                                        <div className='is-centered has-text-centered is-rounded'> <Button className='   is-small has-text-bold' onClick={addMeasurePressed}> ADD + </Button> </div>
                                    // )
                                }

                                <br />
                                <br />


                                {/* {measurement.isConciergeMeasurement ? null : */}
                                <div className='control is-expanded'>
                                    <Button disabled={isUploading} loading={isUploading} className='button is-black is-fullwidth marginTop10' type='submit' onClick={updateMeasures}> {props.purpose === 'useTemplate' ? "Use" : "Save"}  </Button>
                                </div>
                                {/* } */}

                            </ColumnAux>

                        </LoadingAux>






                    </Box>
                </Modal.Content>
            </Modal>

        )
    }

    // props.isNewMeasure, props.measure, props.measureSaved, props.measureDeleted, props.measureIndex

    const measureSaved = (measure, index) => {
        if (selectedMeasure && (index != null)) {
            // Update
            let allMeasures = [...measurement.measures]
            allMeasures[index] = measure
            let ms = { ...measurement }
            ms.measures = allMeasures
            setMeasurement(ms)

        } else {
            // Add


            console.log("Measurement Obj")
            console.log(measurement)


            console.log("Adding Measure")
            console.log(measure)

            let allMeasures = [...measurement.measures]
            allMeasures.push(measure)
            let ms = { ...measurement }
            ms.measures = allMeasures
            setMeasurement(ms)
        }

    }

    const measureDeleted = (ms, index) => {
        if (selectedMeasure && (index != null)) {
            // delete 
            console.log('Measure is being deleted at index ' + index)
            let allMeasures = [...measurement.measures]
            // delete allMeasures[index]
            allMeasures.splice(index, 1);
            let ms = { ...measurement }
            ms.measures = allMeasures
            setMeasurement(ms)
        }
    }


    const useExistingOrChooseChoices = [
        {
            key: 'useExisting',
            title: `${chosenFit} is already selected as Fit-Preference in Style Selections of this product. Do you want to Auto-calculate with ${chosenFit} Auto-calculate values ?`,
            needsRemark: false,
            remark: '',
            remarkPlaceholder: 'Add Changes Needed before Retrial',
            buttonTitle: 'Yes',
            highlight: false
        },
        {
            key: 'letMeChoose',
            title: '',
            needsRemark: false,
            remark: '',
            remarkPlaceholder: 'Add Finishing Points',
            buttonTitle: 'Let me choose',
            highlight: false
        }
    ]

    const askForFitOptions = [
        {
            key: FitPreference.slim,
            title: ``,
            needsRemark: false,
            remark: '',
            remarkPlaceholder: '',
            buttonTitle: FitPreference.slim + ' Fit',
            highlight: false
        },
        {
            key: FitPreference.regular,
            title: '',
            needsRemark: false,
            remark: '',
            remarkPlaceholder: '',
            buttonTitle: FitPreference.regular + ' Fit',
            highlight: false
        },
        {
            key: FitPreference.comfort,
            title: '',
            needsRemark: false,
            remark: '',
            remarkPlaceholder: '',
            buttonTitle: FitPreference.comfort + ' Fit',
            highlight: false
        }
    ]

    const closeModals = () => {
        setAskACfromExistingFit(false)
        setAskFitOpts(false)
        setLoading(false)
    }

    const askACFromExitingChoiceMade = (chosenOpt) => {
        // alert(`Choice made is ${choiceMade.key}`)
        closeModals()

        if (chosenOpt.key === 'useExisting') {
            autoCreateMeasuresWith(chosenFit, custMeasures)
        } else {
            // Ask for fit options
            setAskFitOpts(true)
        }
    }

    // askForFit
    const askForFitChoiceMade = (chosenOpt) => {
        //chosenOpt.key is FitPreference
        closeModals()
        setChosenFit(chosenOpt.key)
        autoCreateMeasuresWith(chosenOpt.key, custMeasures)
    }

    return (
        <div>
            {askACfromExistingFit ? <ChoiceModal title="AutoCalculate with chosen fit ?" choices={useExistingOrChooseChoices} choiceMade={askACFromExitingChoiceMade} closed={closeModals} /> : null}
            {askFitOpts ? <ChoiceModal title="Choose a fit for Auto Calculate" choices={askForFitOptions} choiceMade={askForFitChoiceMade} closed={closeModals} /> : null}

            {addMeasure ? <MeasureEditModal isTemplate={props.isTemplateMode} measureType={props.type} isNewMeasure={true} measureSaved={measureSaved} closed={closed} /> : null}
            {selectedMeasure ? <MeasureEditModal isTemplate={props.isTemplateMode} measureType={props.type} isNewMeasure={false} measure={selectedMeasure.measure} measureSaved={measureSaved} measureIndex={selectedMeasure.index} measureDeleted={measureDeleted} closed={closed} /> : null}
            {modal()}
        </div>
    )

}

export default MeasurementModal