import React from 'react';
import { BrowserRouter } from 'react-router-dom'
import Landing from './containers/Landing'
import { backAppId, backJSId, backMasterKey } from './utilities/clientInfo'
// import { giveBaseName} from './utilities/utils'
import './App.css';
import Parse from 'parse'

function App() {


  // Persona
  Parse.serverURL = 'https://parseapi.back4app.com/';
  Parse.initialize( backAppId, backJSId);
  // Parse.masterKey = backMasterKey

  const devBaseName = '/'
  const productionBaseName = `${process.env.PUBLIC_URL}/`
  

  function giveBaseName() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      // dev code
      console.log('Giving Base Name - ', devBaseName)
      return  devBaseName
    } else {
      // production code
      console.log('Giving Base Name - ', productionBaseName)
      return productionBaseName
    }
  }

  return (
    <BrowserRouter basename={giveBaseName()}>
        <Landing />
    </BrowserRouter>
  ); 
}

export default App;
