import React, { useState } from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Columns, Menu, Tag, Button } from 'react-bulma-components/dist';
import { pages, departments, screen } from '../../utilities/enums'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faDatabase, faFileInvoice, faPassport, faUserCheck, faSms, faShoppingBag, faUser, faMoneyBillAlt, faUserCircle, faCheckCircle, faArrowRight, faArrowLeft, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import MenuProvider from './MenuProvider'
import { getCurrentUserInfo } from '../../utilities/Store'
import { hasFactory } from '../../utilities/clientInfo';
import packageJson from '../../../package.json'


const SideMenu = (props) => {

    let [user, setUser] = useState(getCurrentUserInfo())
    let [showSide, setShowSide] = useState(true)




    // const linkClickedTo = (screen) => {
    //     console.log('LinkClickedTo Called on Id -', screen)

    //     props.linkClickedTo(screen)

    //     // if (dep === departments.Store) {
    //     //     props.history.push('/store')
    //     // } else {
    //     //     props.history.push('/dashboard/' + pageId)
    //     // }
    //     // props.clicked(pageId)
    //     // props.pageChanged(dep)
    // }

    // WITH TAG - Console complains invalid child passed
    // {props.showCheckout ? <Menu.List.Item active={props.pageId === pages.Checkout} onClick={ () => linkClickedTo(pages.Checkout)} > Checkout  <Tag className='badge' color="link" rounded> 1 </Tag> </Menu.List.Item> : null}


    // let storeMenu = () => {

    //     let menu = 

    //     if 

    // }


    const storeM = () => { return <MenuProvider department={departments.Store} currentScreen={props.currentScreen} opened={true} /> }
    const factoryM = () => { return <MenuProvider department={departments.Factory} currentScreen={props.currentScreen} opened={true} /> }
    const vendorM = () => { return <MenuProvider department={departments.Vendors} currentScreen={props.currentScreen} opened={true} /> }
    const clientsM = () => { return <MenuProvider department={departments.Clients} currentScreen={props.currentScreen} opened={true} /> }
    const stocksM = () => { return <MenuProvider department={departments.Stocks} currentScreen={props.currentScreen} opened={true} /> }
    const reportsM = () => { return <MenuProvider department={departments.Reports} currentScreen={props.currentScreen} opened={true} /> }
    const usersM = () => { return <MenuProvider department={departments.Users} currentScreen={props.currentScreen} opened={true} /> }

    const settingsM = () => { return <MenuProvider department={departments.Settings} currentScreen={props.currentScreen} opened={true} /> }


    const allowedLinks = () => {

        if (user.isAdmin) {

            return (
                <div>
                    {storeM()}
                    { hasFactory ? factoryM() : null}
                    {vendorM()}
                    {clientsM()}
                    {/* {stocksM()} */}
                    {reportsM()}
                    {usersM()}
                    {settingsM()}
                </div>
            )
        } else {

            return (
                <div>
                    {user.position.control.ordersControl.storeControl.isAllowed === true ? storeM() : null}
                    { hasFactory ? (user.position.control.ordersControl.factoryControl.isAllowed === true ? factoryM() : null) : null}
                    {user.position.control.ordersControl.vendorControl.isAllowed === true ? vendorM() : null}
                    {user.position.control.clientsControl.isAllowed === true ? clientsM() : null}
                    {/* {user.position.control.stocksControl.isAllowed === true ? stocksM() : null} */}
                    {user.position.control.reportsControl.isAllowed === true ? reportsM() : null}
                    {user.isFranchiseAdmin === true ? usersM() : null}
                </div>
            )

        }

    }

    const showSideBar = () => {
        setShowSide(true)
    }

    const hideSideBar = () => {
        setShowSide(false)
    }


    const sm = () => {
        return (
            <Columns.Column className='one-fifth is-narrow   is-fullheight is-hidden-touch sideMenu sideMenuBG noScrollSideBar easer' narrow>
                <Columns className="is-narrow pad0" >
                    <Columns.Column size={10}>
                        <Menu renderAs='aside' className='aside padTop10 padHorizontal20 type76' >
                            <Menu.List title="">
                                {allowedLinks()}
                            </Menu.List>
                            <div className='space80'>
                            </div>
                            <p className="has-text-grey-light type50 has-text-centered">{packageJson.version}</p>

                        </Menu>
                    </Columns.Column>
                    <Columns.Column className="pad0 " size={2}>
                        <Button className="  is-fullheight  sideMenuBG is-pulled-right is-borderless showSideBarButton has-text-grey" onClick={hideSideBar}> <FontAwesomeIcon className='' icon={faChevronLeft} /> </Button>
                    </Columns.Column>
                </Columns>
            </Columns.Column>
        )
    }

    const showBtn = () => {
        return (
            <div className="easer">
                <Button className=" has-text-grey is-fullheight is-hidden-touch noScrollSideBar  is-borderless sideMenuBG" onClick={showSideBar}> <FontAwesomeIcon className='' icon={faChevronRight} /> </Button>

            </div>
        )
    }


    const currentPage = () => {
        if (showSide) {
            return sm()
        }
        return showBtn()
    }

    return (
        currentPage()
    )
}

export default withRouter(SideMenu)