import React from 'react'
import PageHeader from '../../containers/PageHeader'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Columns, Container, Section, Heading, Breadcrumb, Tabs, Image, Button, Modal } from 'react-bulma-components/dist';

import ClientWindow from './ClientWindow/clientWindow'
import FactoryWindow from './FactoryWindow/factoryWindow'
import VendorWindow from './VendorWindow/vendorWindow'
import SearchOrders from './searchOrders'
import { clientName } from './../../utilities/clientInfo'
import client from './client'
import RecentOrders from './recentOrders';
import PendingInfo from './pendingInfo';


const Store = (props) => {

    let pathOrder = () => {
        let path = props.location.pathname
        let links = path.split('/')

        // removing initial blank item after split
        links.shift()

        return links
    }

    let lastPath = () => {
        let path = pathOrder()
        let thisTitle = path[path.length - 1]
        return thisTitle
    }


    let noPage = () => {
        return (
            <Container>
                <Heading size={5} className=' has-text-grey-light ' > Welcome to {clientName} </Heading>
                <Section>
                    
                </Section>
            </Container>
        )
    }

    let thisPage = () => {

        console.log('LAST PATH IS ' + lastPath())

        switch (lastPath()) {
            case 'clientWindow': return <ClientWindow />;
            case 'factoryWindow': return <FactoryWindow />;
            case 'vendorWindow': return <VendorWindow />;
            case 'recentOrders': return <RecentOrders />;
            case 'pendingInfo': return <PendingInfo />;
            case 'search': return <SearchOrders />;
            default:
                return noPage();
        }

    }

    return (
        <div>
            <PageHeader crumbItems={pathOrder()} />
            {thisPage()}
        </div>
    )

}

export default Store