import cogoToast from 'cogo-toast';
import React, { useEffect, useState } from 'react'
import { Box, Columns, Button, Tag, Heading } from 'react-bulma-components/dist';
import { StyleSelection } from '../../utilities/Classes'
import StyleSelectionModal from './styleSelectionModal'
import ProductSelectionModal from './productSelectionModal'
import ChoiceModal from './../../components/Cards/ChoiceModal'
import ColumnAux from '../../containers/ColumnAux';

import { StyleSelectionProductType_options_NonFormik } from '../../utilities/enums'



const StyleSelections = (props) => {


    const setupSelections = () => {
        let ss = StyleSelection.init()
        ss.name = 'Front Style'
        ss.applicableOn = ['2pc suit', '3pc suit', 'Jacket', 'Pant']
        ss.options = ['4 Button', '6 Button', '2 Button']

        let allSS = [ss, ss, ss, ss, ss, ss, ss]
        return allSS
    }

    const [selections, setSelections] = useState([])
    const [filteredSelections, setFilteredSelections] = useState([])
    const [showSelectionModal, setShowSelectionModal] = useState(null)
    const [newSelectionModal, setNewSelectionModal] = useState(false)
    const [forProductFilter, setForProductFilter] = useState([])
    const [showProductSelection, setShowProductSelection] = useState(false)
    const [fetching, setFetching] = useState(false)
    const [filterChoice, setFilterChoice] = useState("")


    useEffect(() => {

        StyleSelection.getAll([], (succ, sss, errMsg) => {
            if (succ) {

                sss.sort(function(a, b){
                    if(a.name < b.name) { return -1; }
                    if(a.name > b.name) { return 1; }
                    return 0;
                })

                setSelections(sss)
                setFilteredSelections(sss)
            } else {
                console.log('Error while fetching StyleSelections. ' + errMsg)
                cogoToast.error('Could not fetch Style Selections. Check internet and reload.')
            }
        })

    }, [])



    const getSelections = () => {
        StyleSelection.getAll([], (succ, sss, errMsg) => {
            if (succ) {

                sss.sort(function(a, b){
                    if(a.name < b.name) { return -1; }
                    if(a.name > b.name) { return 1; }
                    return 0;
                })

                setSelections(sss)
                setFilteredSelections(sss)
            } else {
                console.log('Error while fetching StyleSelections. ' + errMsg)
                cogoToast.error('Could not fetch Style Selections. Check internet and reload.')
            }
        })
    }


    const viewPressed = (index) => {
        console.log('VIEW IS PRESSED')
        setShowSelectionModal(
            {
                selection: selections[index],
                index: index
            }

        )
    }



    const headerRow = () => {

        return (
            <div className='marginBottom10'>
                <Columns className=' is-size-7'>
                    <Columns.Column size={2} className=''>
                        <p className='maxWrap165 marginLeft20 has-text-grey-light '> NAME </p>
                    </Columns.Column>
                    <Columns.Column size={3} className=''>
                        <p className='maxWrap165 marginLeft20 has-text-grey-light '> FOR </p>
                    </Columns.Column>
                    <Columns.Column className=' '>
                        <p className="maxWrap165 has-text-grey-light" > OPTIONS </p>
                    </Columns.Column>
                    <Columns.Column size={2} className=''>
                    </Columns.Column>
                </Columns>
            </div>
        )
    }

    const ssBox = (ss, index) => {

        let applyOn = ss.applicableOn.join(' ')
        let desc = ss.options.join(', ')

        // let pos = new Position('', null, '')

        //  id, orderNo, productsCount, bookingD, trialD, deliveryD, status

        return (
            <Box key={ss.name + `${index}`} className='hoverShine noBreakWords generalCardBG' onClick={() => { viewPressed(index) }} style={{ cursor: 'pointer' }} >
                <Columns className=' is-size-7'>
                    <Columns.Column size={2} className=''>
                        {/* <p className='has-text-grey-light has-text-weight-semibold  marginBottom5'> POSITION </p> */}
                        <p className='maxWrap165 has-text-weight-semibold'>{ss.name} </p>
                        {/* { order.addedByUser ?  <p className='type50'>via {order.addedByUser.name}  </p>  : null} */}
                    </Columns.Column>
                    <Columns.Column size={3} className=''>
                        {/* <p className='has-text-grey-light has-text-weight-semibold  marginBottom5'> POSITION </p> */}
                        <p className='maxWrap165 has-text-grey has-text-weight-semibold'> For {applyOn} </p>
                        {/* { order.addedByUser ?  <p className='type50'>via {order.addedByUser.name}  </p>  : null} */}
                    </Columns.Column>
                    <Columns.Column className=' '>
                        {/* <p className='has-text-grey-light has-text-grey  marginBottom5'> Access </p> */}
                        <p color="maxWrap165 light" >{desc}</p>
                    </Columns.Column>
                    {/* <Columns.Column size={2} className=''>
                        <Button className='is-small is-fullwidth is-outlined' onClick={() => { viewPressed(index) }}> View </Button>
                    </Columns.Column> */}
                </Columns>
            </Box>
        )
    }

    const addPressed = () => {
        setNewSelectionModal(true)
    }


    const calculateFilteredSelections = (choice) => {
        if (choice !== "" && choice !== "ALL"){

            console.log("CALCULATING FILTER CHOICE for ", choice)

            let filtered = selections.filter( (thisSelection) => {
                return thisSelection.applicableOn.includes(choice.toUpperCase())
            })

            console.log("FILTERED CHOICES ", choice)
            console.log(filtered)

            setFilteredSelections(filtered)
        }else{
            setFilteredSelections(selections)
        }

    }


    const filterChoiceChanged = (e) => {
        console.log("Filter Choice Changed")
        console.log(e.target.value.toUpperCase())

        setFilterChoice(e.target.value.toUpperCase())
        calculateFilteredSelections(e.target.value.toUpperCase())
    }

    const selectionList = () => {
        return <div>
            <div>
                <ColumnAux isMiddle={true} size={12}>
                    <Button className='is-small is-pulled-right is-rounded' onClick={addPressed}> Add </Button>
                </ColumnAux>

                <Columns>
                    <Columns.Column>
                        <p className=" is-size-7 has-text-weight-bold"> Show For Products</p>
                        <div className="control ">
                            <div className='select  '>
                                {StyleSelectionProductType_options_NonFormik('type', '  ', filterChoiceChanged, filterChoice)}


                                {/* <select name='unit' className='select has-background-grey-dark has-text-white has-text-bold' defaultValue={2} onChange={e => { filterChoiceMade(e) }}>
                                    <option value={2} > Show For All Products </option>
                                    <option value={6} > For Suit 2 Piece </option>
                                </select> */}
                            </div>
                        </div>
                        <br />

                    </Columns.Column>
                    <Columns.Column size={3}>
                    </Columns.Column>
                </Columns>


            </div>
            {
                filteredSelections.map((ss, index) => {
                    return ssBox(ss, index)
                })
            }
        </div>
    }

    const closeModals = () => {
        setShowSelectionModal(null)
        setNewSelectionModal(false)
    }

    const selectionSaved = (ss, index) => {
        let sss = [...selections]
        sss[index] = ss
        sss.sort(function(a, b){
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
        })

        setSelections(sss)
        closeModals()
        calculateFilteredSelections(filterChoice)

    }


    const selectionDeleted = (ss, index) => {
        let sss = [...selections]
        sss.splice(index, 1);

        sss.sort(function(a, b){
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
        })

        setSelections(sss)
        setFilteredSelections(sss)
        setFilterChoice("ALL")
        closeModals()
        // calculateFilteredSelections(filterChoice)

    }

    const selectionAdded = (ss) => {
        let sss = [...selections]
        sss.unshift(ss)
        sss.sort(function(a, b){
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
        })

        setSelections(sss)
        setFilteredSelections(sss)
        setFilterChoice("ALL")
        closeModals()
        // calculateFilteredSelections("")
    }






    return (
        <div>
            <br />
            <Heading size={6} className='has-text-centered'> Style Selections </Heading>
            <br />
            { showSelectionModal ? <StyleSelectionModal styleSelection={showSelectionModal.selection} styleSelectionIndex={showSelectionModal.index} closed={closeModals} isNewSelection={false} selectionSaved={selectionSaved} selectionDeleted={selectionDeleted} /> : null}
            { newSelectionModal ? <StyleSelectionModal styleSelection={StyleSelection.init()} styleSelectionIndex={null} closed={closeModals} isNewSelection={true} selectionAdded={selectionAdded} /> : null}

            { selectionList()}
        </div>
    )
}

export default StyleSelections





