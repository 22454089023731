import React, { useState, useRef, useEffect } from 'react'
import { Tile, Columns, Heading, Button } from 'react-bulma-components/dist'
import VendorCard from '../components/Cards/VendorCard'
import { Vendor } from '../utilities/Classes'
import VendorProfile from '../components/Cards/vendor-profile'
import VendorModal from '../components/Cards/VendorModal'
// import { CustomerFilter } from '../utilities/Filters'
import Client from '../Departments/Store/client'
import { screen, screenMenuLink } from '../utilities/enums'
import cogoToast from 'cogo-toast'
import LoadingAux from '../containers/LoadingAux'


const Vendors = (props) => {


    // let SearchKeys = {
    //     ClientPhone: 'Client Phone',
    //     ClientEmail: 'Client Email',
    //     ClientName: 'Client Name',
    // }

    let [vendors, setVendors] = useState([])
    // let [custFilter, setCustFilter] = useState(CustomerFilter.init())

    let [fetchVendors, setFetchVendors] = useState(props.isSearchable ? false : true)
    let [selectedVendor, setSelectedVendor] = useState(null)

    let [addVendor, setAddVendor] = useState(false)
    let [fetching, setFetching] = useState(false)


    // let [searchKey, setSearchKey] = useState(SearchKeys.ClientPhone)
    // let [searchVal, setSearchVal] = useState(null)
    // let [searching, setSearching] = useState(false)
    // const valFieldRef = useRef('valFieldRef')


    useEffect(() => {
        if (fetchVendors === true) {
            setFetching(true)

            Vendor.getAll((succ, vendors, msg) => {
                setFetching(false)
                if (succ) {
                    setVendors(vendors)
                } else {
                    cogoToast.error('Could not fetch Vendors. An error occured.')
                    // console.log(msg)
                }
            })

            setFetchVendors(false)
        }
    }, [fetchVendors])



    const closeModals = () => {
        setSelectedVendor(null)
        setAddVendor(false)
    }



    // const searchKeyChanged = (e) => {
    //     setSearchVal(null)
    //     setCustFilter(CustomerFilter.init())
    //     setVendors([])
    //     valFieldRef.current.value = null
    //     setSearchKey(e.target.value)
    // }

    // const searchValChanged = (e) => {
    //     setSearchVal(e.target.value)
    // }



    const selectVendorPressed = (vend) => {

        if (props.isToChoose) {
            props.vendorChosen(vend)
        } else {
            setSelectedVendor(vend)
        }

    }

    const vendorUpdated = (vnd) => {
        let vends = [...vendors]

        const index = vends.findIndex((cs) => {
            return cs.objectId === vnd.objectId
        })

        if (index != null) {
            vends[index] = vnd
        }

        setVendors(vends)
    }


    const vendorAdded = (vnd) => {
        let vends = [...vendors]
        vends.unshift(vnd)
        setVendors(vends)
    }


    // const startSearching = () => {
    //     if (searchVal != null) {
    //         // search
    //         if (searchKey === SearchKeys.ClientPhone) {
    //             custFilter.phone = searchVal
    //         }

    //         if (searchKey === SearchKeys.ClientEmail) {
    //             custFilter.email = searchVal
    //         }

    //         if (searchKey === SearchKeys.ClientName) {
    //             custFilter.name = searchVal
    //         }
    //         searchForCustomers()

    //     } else {
    //         alert('Enter Value to Search')
    //         return
    //     }
    // }

    // const searchForCustomers = () => {
    //     if (fetchVendors === true || props.isSearchable === true) {

    //         if (fetchVendors) {
    //             setFetchVendors(false)
    //         }

    //         // console.log('Searching for Customers for screen- ' + props.screen)

    //         setSearching(true)
    //         Customer.getAll(custFilter, (succ, vendors, msg) => {
    //             if (succ) {
    //                 setVendors(vendors)
    //             } else {
    //                 console.log(msg)
    //             }
    //             setSearching(false)
    //         })

    //     } else {
    //         console.log('Fetch Customers is false')
    //     }
    // }



    // let searchForm = () => {

    //     return (

    //         <div>
    //             <Heading size={5} className='is-centered  has-text-centered'> Customers where</Heading>
    //             <div>
    //                 <div className="field has-addons has-addons-centered">
    //                     <div className="control ">
    //                         <span className="select has-text-weight-semibold type70">
    //                             <select as='select' defaultValue={searchKey} className='select' name='searchKey' onChange={(e) => { searchKeyChanged(e) }}>
    //                                 <option value={SearchKeys.ClientPhone} >{SearchKeys.ClientPhone}</option>
    //                                 <option value={SearchKeys.ClientEmail} >{SearchKeys.ClientEmail}</option>
    //                                 <option value={SearchKeys.ClientName} >{SearchKeys.ClientName}</option>
    //                             </select>
    //                         </span>
    //                     </div>
    //                     <div className='control'>
    //                         <div className=' field'>
    //                             <input ref={valFieldRef} name='searchVal' defaultValue={searchVal ?? null} type='input' className='input has-text-weight-semibold type70' placeholder='Equal to' onChange={(e) => { searchValChanged(e) }} />
    //                         </div>
    //                     </div>
    //                     <div className='control'>
    //                         <Button disabled={searching} loading={searching} className='type70 is-black ' onClick={() => { startSearching() }} > Search </Button>
    //                     </div>
    //                 </div>
    //             </div>
    //             <br />

    //         </div>
    //     )
    // }


    const vendorsView = () => {
        return (
            <div>
                <div>
                    {/* {(props.isSearchable === true) ? searchForm() : null} */}
                </div>
                {/* <Heading size={6} className="  marginBottom6">Customers </Heading> */}

                <Tile kind="ancestor" className=' vendorGrid' >
                    {/* <Fade right cascade> */}
                    {
                        vendors.map((vend, index) => {
                            return (
                                <Tile key={index} kind='parent' className=''>
                                    <Tile className='' >
                                        <VendorCard isToChoose={props.isToChoose ?? false} key={index} vendor={vend} selectVendor={selectVendorPressed} />
                                    </Tile>
                                </Tile>
                            )
                        })
                    }
                    {/* </Fade> */}
                </Tile>
            </div>
        )
    }


    const addVendorPressed = () => {
        // console.log('ADD VENDOR PRESSED')
        setAddVendor(true)
    }


    return (
        <LoadingAux isLoading={fetching} loaderHeight={300} size='medium'>
            <div>
                {/* {selectedVendor ? <VendorModal   closed={closeModals} vendor={selectedVendor} isNewVendor={false} vendorAdded={vendorAdded} vendorUpdated={vendorUpdated} /> : null} */}
                {(addVendor === true) ? <VendorModal closed={closeModals} vendor={null} isNewVendor={true} vendorAdded={vendorAdded} vendorUpdated={vendorUpdated} /> : null}

                <br />
                <br />
                {selectedVendor ?
                    <VendorProfile screen={props.screen ?? screen.Vendors} vendor={selectedVendor} backPressed={closeModals} />

                    :
                    (
                        <div>
                            <div className='marginBottom30'>

                                {(props.isToChoose ?? false) ?
                                    null

                                    :
                                    <div>
                                        <Button className='is-small is-pulled-right is-rounded marginBottom10' onClick={addVendorPressed}> Add </Button>
                                        <br />
                                    </div>
                                }

                            </div>
                            <div className=''>
                                {vendorsView()}
                            </div>
                        </div>

                    )

                }
            </div>
        </LoadingAux>

    )

}


export default Vendors