import React, { useState } from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Tile, Box, Columns, Button, Tag, Image, Modal, Heading, Card, Label, InputFile, Dropdown } from 'react-bulma-components/dist';
import { pages, departments, screen, departmentForScreen, clientScreenTab, Posture, imageUrlForPostures, ProductType_options_NonFormik } from '../utilities/enums'
import ColumnAux from './ColumnAux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPrint, faUpload } from '@fortawesome/free-solid-svg-icons';
import LoadingAux from './LoadingAux';
import { CustomerPic, ProductPic } from '../utilities/Classes';
import cogoToast from 'cogo-toast';
import { layer } from '@fortawesome/fontawesome-svg-core';


const PatternModal = (props) => {


    const backPressed = () => {
        props.closed()
    }

    const patternDetailsFromExisting = (ep) => {

        // console.log("Incoming Existing Pattern is ");
        // console.log(ep)

        if (ep) {
            let index = ep.index;
            let pp = ep.pattern + "";
            if (pp.length) {

                let parts = pp.split('-');
                let p1 = parts[0] ?? null;
                let p2 = parts[1] ?? null;

                return { patternProduct: p1, patternNumber: p2 }
            }
        }
        return { patternProduct: null, patternNumber: null }

    }

    const [patternProduct, setPatternProduct] = useState(patternDetailsFromExisting(props.existingPattern).patternProduct ?? null)
    const [patternNumber, setPatternNumber] = useState(patternDetailsFromExisting(props.existingPattern).patternNumber ?? null)
    const [isSaving, setIsSaving] = useState(false)




    const savePressed = () => {
        if (patternProduct === null) {
            return
        }

        if (patternNumber === null) {
            cogoToast.warn("As you did not enter Pattern number. 0000 is used.")
        }

        let np = patternProduct + "-" + (patternNumber ?? "0000")

        setIsSaving(true)
        if (props.existingPattern) {
            props.patternChanged(np, false)
        } else {
            props.patternAdded(np)
        }
    }


    const productTypeChanged = (type) => {
        let pr = type.target.value
        setPatternProduct(pr)
        console.log('Product Type Changed = ', pr)
    }

    const productNumberChanged = (e) => {
        let val = e.target.value
        console.log('Product Number Changed = ' + val)
        setPatternNumber(val)
    }


    const form = () => {
        return (
            <div>
                <Columns className='is-mobile'>
                    <Columns.Column size={3}>
                        <label className="label type55  marginTop10 has-text-right">Product:</label>
                    </Columns.Column>
                    <Columns.Column>
                        <span className="select has-text-weight-semibold type65 ">
                            {ProductType_options_NonFormik('type', '  ', productTypeChanged, patternProduct, true)}
                        </span>
                    </Columns.Column>
                </Columns>

                <div>
                    <Columns className='is-mobile'>
                        <Columns.Column size={3}>
                            <label className="label type55  marginTop10 has-text-right">Pattern ID:</label>
                        </Columns.Column>
                        <Columns.Column>
                            <div className="control width180">
                                <div className='field '>
                                    <input name='styleNo' defaultValue={patternNumber} placeholder='Pattern Id' type='input' className='input field has-text-weight-semibold type65' onChange={e => { productNumberChanged(e) }} />
                                </div>
                            </div>
                        </Columns.Column>
                    </Columns>
                </div>
            </div>
        )
    }


    const dropdownPressed = (val) => {
        // console.log("Dropdown pressed")
        // console.log(val)

        if (val === "delete") {
            setIsSaving(true)
            props.patternChanged(null, true)
        }
    }

    const modal = () => {
        return (
            <Modal show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={() => props.closed()} >
                <Modal.Content className='is-padingless has-background-light'>

                    {
                        patternProduct ?
                            <Dropdown className=" is-arrowless is-grey noDropdownIcon" color="light" label='•••' onChange={(val) => { dropdownPressed(val) }} >
                                <Dropdown.Item value="delete">
                                    Delete
                                </Dropdown.Item>
                            </Dropdown>

                            :
                            null
                    }


                    <Box className='is-radiusless is-padingless no-border is-shadowless has-background-transparent '>

                        {/* <Button className="" onClick={savePressed}> Done </Button> */}
                        <LoadingAux isLoading={false} loaderHeight={300} >
                            {/* <Dropdown className=" is-arrowless is-grey noDropdownIcon" color="light" label='•••' onChange={(val) => { deletePressed(val) }} >
                                <Dropdown.Item value="delete">
                                    Delete
                                </Dropdown.Item>
                            </Dropdown> */}

                            {/* <Heading size={6} className='is-size-5-mobile has-text-black has-text-semibold has-text-centered' > Image </Heading> */}
                            <ColumnAux isMiddle={true} size={8} className=' is-centered '>

                                <Heading size={5} className="has-text-grey-light has-text-centered"> Pattern </Heading>
                                <br />
                                {form()}
                                <br />
                                <Button disabled={isSaving} loading={isSaving} className='is-fullwidth is-black' onClick={savePressed} > Save </Button>
                                <br />


                            </ColumnAux>
                            <br />
                        </LoadingAux>


                    </Box>
                </Modal.Content>
            </Modal>

        )
    }


    return (
        modal()
    )

}

export default PatternModal