import React, { useState } from 'react'
import ProductCard from '../components/Cards/ProductCard'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Columns, Container, Section, Heading, Breadcrumb, Tabs, Image, Button, Control, Box, Tile, Dropdown, Card } from 'react-bulma-components/dist';
import { pages, departments, screen, departmentForscreen, clientScreenTab, Department, Status, PrintSheetType } from '../utilities/enums'
import { dateToDateString } from '../utilities/helper'
import { Product, productListTitleForScreen, nextStageForScreen, nextStageForScreen_Title, canAddOrderAndProducts, Customer, nextStageCompletion_Title } from '../utilities/Classes'
import ColumnAux from './ColumnAux';
import PrintOrder from './PrintOrder';
import OrderModal from '../components/Cards/OrderModal'
import ProductModal from '../components/Cards/ProductModal'
import ConciergeOrderModal from '../components/Cards/ConciergeOrderModal'

import VendorsModal from '../General/VendorsModal'



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPrint, faBackward, faBackspace, faChevronLeft, faEdit, faPaperPlane, faStickyNote, faFile, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { getCurrentUser } from '../utilities/Store';
import cogoToast from 'cogo-toast';
import ClientInfoModal from './ClientInfoModal';

// props.customer, props.order, props.fetchProducts, props.screen, props.backPressed

const ProductsOfOrder = (props) => {

    const [order, setOrder] = useState(props.order)
    const [products, setProducts] = useState([])
    const [customer, setCustomer] = useState(props.customer ? props.customer : null)

    const [cancelledProducts, setCancelledProducts] = useState([])

    const [fetchProducts, setFetchProducts] = useState(props.fetchProducts)
    const [noProductsMessage, setNoProductsMessage] = useState('')

    const [selectedProducts, setSelectedProducts] = useState([])
    const [sending, setSending] = useState(false)
    let [showVendors, setShowVendors] = useState(false)

    const [printingOrder, setPrintingOrder] = useState(false)

    const [addNewProduct, setAddNewProduct] = useState(false)
    const [viewProduct, setViewProduct] = useState(null)
    const [viewOrder, setViewOrder] = useState(null)

    const [showConcOrderDetails, setShowConcOrderDetails] = useState(false)
    const [showClientInfoModal, setShowClientInfoModal] = useState(false)


    let productsTitle = productListTitleForScreen(props.screen)




    let cu = getCurrentUser()
    let dept = departmentForscreen(props.screen ?? screen.NewOrder)


    const clientDetailsVisibility = () => {
        let al = true
        if (cu.isAdmin) {
            return al
        }
        switch (dept) {
            case departments.Store: al = cu.position.control.ordersControl.storeControl.clientDetailsVisibility; break;
            case departments.Factory: al = cu.position.control.ordersControl.factoryControl.clientDetailsVisibility; break;
            case departments.Vendors: al = cu.position.control.ordersControl.vendorControl.clientDetailsVisibility; break;
            default: break;
        }
        return al
    }

    const addingNewProductsAllowed = () => {
        let al = true
        if (cu.isAdmin) {
            return al
        }

        switch (dept) {
            case departments.Store: al = cu.position.control.ordersControl.storeControl.addingNewProducts; break;
            case departments.Factory: al = cu.position.control.ordersControl.factoryControl.addingNewProducts; break;
            case departments.Vendors: al = cu.position.control.ordersControl.vendorControl.addingNewProducts; break;
            default: break;
        }
        return al
    }

    const actingOnProductsAllowed = () => {
        let al = true
        if (cu.isAdmin) {
            return al
        }
        switch (dept) {
            case departments.Store: al = cu.position.control.ordersControl.storeControl.actingOnProducts; break;
            case departments.Factory: al = cu.position.control.ordersControl.factoryControl.actingOnProducts; break;
            case departments.Vendors: al = cu.position.control.ordersControl.vendorControl.actingOnProducts; break;
            default: break;
        }
        return al
    }

    const addingPicsMeasurementsAllowed = () => {
        let al = true
        if (cu.isAdmin) {
            return al
        }
        switch (dept) {
            case departments.Store: al = cu.position.control.ordersControl.storeControl.addingPicsMeasurementsRemarks; break;
            case departments.Factory: al = cu.position.control.ordersControl.factoryControl.addingPicsMeasurementsRemarks; break;
            case departments.Vendors: al = cu.position.control.ordersControl.vendorControl.addingPicsMeasurementsRemarks; break;
            default: break;
        }
        return al
    }

    let canAddProducts = (canAddOrderAndProducts(props.screen) && addingNewProductsAllowed())

    let newProduct = () => {
        let np = Product.init()
        if (order) {
            np.order = order
            if (order.trialDate) { np.trialDate = order.trialDate }
            if (order.deliveryDate) { np.deliveryDate = order.deliveryDate }
        }
        if (props.customer) {
            np.customer = props.customer
        }
        return np
    }

    const closeModals = () => {
        setAddNewProduct(false)
        setViewProduct(null)
        setViewOrder(null)
        setShowVendors(false)
        setShowConcOrderDetails(false)
        setPrintingOrder(false)
        setShowClientInfoModal(false)
    }

    const closeClientInfoModal = (cust) => {
        if (cust) {
            setCustomer(cust)
        }
        setShowClientInfoModal(false)
    }

    const addNewProductPressed = () => {
        setAddNewProduct(true)
    }

    const showConciergeDetails = () => {
        setShowConcOrderDetails(true)
    }

    const showClientInfoModalPressed = () => {

        if (customer) {
            setShowClientInfoModal(true)
            return
        }

        Customer.getCompleteInfo(order.customerObjId, (success, customer, errMsg) => {
            if (success) {
                // cogoToast.success("Got Customer complete info")
                console.log("Customer complete info is")
                console.log(customer)
                setCustomer(customer)

                setShowClientInfoModal(true)

            } else {
                console.log("Could not fetch customer info to show in Client Info modal.")
                cogoToast.error("Some error occured. Please check internet and try again.")
            }

            // setPrintingOrder(true)
        })

    }

    const viewProductPressed = (prod) => {
        setViewProduct(prod)
    }

    const orderEditPressed = () => {
        console.log('Order Edit was pressed')
        setViewOrder(order)
    }

    const orderPrintPressed = () => {
        // get customer of order 

        if (fetchProducts) {
            cogoToast.warn("Products are being loaded. Try after it is finished")
            return
        }

        console.log("ORDER DETAILS ARE")
        console.log(order)

        console.log("ORDER customerObjId")
        console.log(order.customerObjId)

        // order.customerObjId  get customer details from hee

        Customer.getCompleteInfo(order.customerObjId, (success, customer, errMsg) => {
            if (success) {
                // cogoToast.success("Got Customer complete info")
                console.log("Customer complete info is")
                console.log(customer)
                setCustomer(customer)


                setPrintingOrder(true)



            } else {
                console.log("Could not fetch customer complete info for order print")
                cogoToast.error("")
            }

            // setPrintingOrder(true)
        })


    }

    const orderWasUpdated = (ord) => {
        // setOrder(ord)
        if (props.orderUpdated != null) {
            props.orderUpdated(ord)
        }
    }

    const searchProducts = () => {

        if (fetchProducts === true) {
            console.log('FETCHING PRODUCTS')
            Product.getSelected(order, true, props.screen, (success, prods, errorMsg) => {
                if (success) {
                    if (prods.length > 0) {

                        // Seperate Cancelled Products

                        let activeProds = []
                        let cancelledProds = []


                        prods.map((thisProd) => {
                            if (thisProd.isCancelled === true) {
                                cancelledProds.push(thisProd)
                            } else {
                                activeProds.push(thisProd)
                            }
                        })

                        setProducts(activeProds)

                        if (cancelledProds !== null) {
                            if (cancelledProds.length) {
                                if (cancelledProds.length > 0) {
                                    setCancelledProducts(cancelledProds)
                                }
                            }
                        }



                    } else {
                        setNoProductsMessage(errorMsg)
                    }
                    setFetchProducts(false)
                } else {
                    cogoToast.error('Could not find Products. An error occured.')
                    // console.log(errorMsg)
                }
            })
        } else {
            // alert('PRODUCT FETCH FALSE')
        }

    }


    const productAdded = (prod) => {
        let prods = [...products]
        prods.push(prod)
        setProducts(prods)
        // let ord = {...order}
        // ord.totalProductsCount += 1
        // setOrder(ord)
    }

    const productUpdated = (prod) => {
        let prods = [...products]
        const index = prods.findIndex((pr) => {
            return pr.objectId === prod.objectId
        })
        if (index != null) {
            if (prod.isCancelled === true) {
                // remove product from main array
                prods.splice(index, 1);

                //and put it in cancelled products
                let cPs = [...cancelledProducts]
                cPs.push(prod)
                setCancelledProducts(cPs)

            } else {
                prods[index] = prod
            }
        }
        setProducts(prods)
    }

    const productSelected = (prod) => {
        let prods = [...products]
        let selectedProds = prods.filter((prd) => {
            return prd.isSelected
        })
        setSelectedProducts(selectedProds)
    }



    const orderInfoView = (order) => {
        return (
            <div className='has-text-centered'>

                <p>
                    <Button className="  no-decoration is-pulled-left is-small has-text-weight-bold" onClick={() => { props.backPressed() }}><FontAwesomeIcon className='' icon={faChevronLeft} /></Button>

                </p>
                <br />
                <br />

                <p></p>
                {/* <Box> */}
                <div >

                    {
                        (order.isCancelled === true) ?

                            <Heading size={5} className=''> {order.isConciergeOrder ? <span className=" has-text-bold conciergeIconText">C</span> : null} #{order.orderNo + " (Cancelled)"} <span className=' has-text-weight-normal type50'> {order.addedByUser ? <span className='type50'> ·&nbsp; by {order.addedByUser.name} &nbsp; </span> : null} ·&nbsp;<span className={order.status === Status.Delivered ? " has-text-success" : ""}>{order.status}</span>&nbsp;  </span>
                                {/* <span className='has-text-weight-light is-size-7'>via {orderInfo.bookingAgent}  </span> */}
                                {/* <span className=' has-text-weight-normal is-size-7'>· </span>  <Button className="type74  has-text-grey-light  no-decoration is-text is-small"><FontAwesomeIcon className='' icon={faPrint} /></Button> */}
                                <span className=' has-text-weight-normal is-size-6'> · </span>  <Button className="  has-text-grey-light  no-decoration is-text " onClick={orderEditPressed} > <FontAwesomeIcon className='' icon={faEdit} /></Button> <span className="type50">·</span> <Button className="  has-text-grey-light  no-decoration is-text " onClick={orderPrintPressed} > <FontAwesomeIcon className='' icon={faPrint} /></Button>
                            </Heading>
                            :
                            // <Heading size={5} className=''> {order.isConciergeOrder ? <span className=" has-text-bold conciergeIconText">C</span> : null} #{order.orderNo + (order.isCancelled === true ? " (Cancelled)" : "")} <span className=' has-text-weight-semibold type50 has-text-grey-light' > ·&nbsp; <span className={order.status === Status.Delivered ? " has-text-success" : ""}>{order.status}</span> &nbsp; {order.addedByUser ? <span className='type50 has-text-weight-normal'> ·&nbsp; via {order.addedByUser.name} &nbsp; </span> : null}  </span>
                            <div>
                                <p className='is-size-5-mobile has-text-grey-lighter  has-text-centered has-text-weight-semibold'>  Order Details </p>
                                <Heading size={5} className=''> {order.isConciergeOrder ? <span className=" has-text-bold conciergeIconText">C</span> : null} #{order.orderNo}</Heading>
                                {order.posNo ? (order.posNo.length > 1 ? (<p className="is-size-6 has-text-grey-light has-text-centered topMarginMinus10 marginBottom10"> (POSNo:  {order.posNo}) </p>) : null) : null}
                                <Heading size={5} className='  topMarginMinus6'> <span className=' has-text-weight-semibold type50 has-text-grey-light ' >
                                    <Button className="type74 noMargin  has-text-grey-light  no-decoration is-text is-small" onClick={orderEditPressed} ><FontAwesomeIcon className='' icon={faEdit} /></Button>
                                    <span className=' has-text-weight-normal is-size-7 has-text-grey-light'>·</span>

                                    &nbsp;<span className={order.status === Status.Delivered ? " has-text-success" : ""}>{order.status}</span>  </span>

                                    {/* <Heading size={5} className=''> {order.isConciergeOrder ? <span className=" has-text-bold conciergeIconText">C</span> : null} #{order.orderNo + (order.isCancelled === true ? " (Cancelled)" : "")} <span className=' has-text-weight-semibold type50 has-text-grey-light' > ·&nbsp; <span className={order.status === Status.Delivered ? " has-text-success" : ""}>{order.status}</span>  </span> */}

                                    {/* <span className='has-text-weight-light is-size-7'>via {orderInfo.bookingAgent}  </span> */}
                                    {/* <span className=' has-text-weight-normal is-size-7'>· </span>  <Button className="type74  has-text-grey-light  no-decoration is-text is-small"><FontAwesomeIcon className='' icon={faPrint} /></Button> */}
                                
                                    <span className="type50 has-text-grey-light">·</span>
                                    <Button className=" topMarginMinus4 has-text-grey-light  no-decoration is-text " onClick={orderPrintPressed} > <FontAwesomeIcon className=' type74' icon={faFileAlt} /></Button>

                                </Heading>

                            </div>


                    }

                </div>




                <div className=' '>
                    {/* <Button className='is-small is-borderless' disabled>B: {order.bookingDate ? dateToDateString(order.bookingDate) : null} </Button>
                    <Button className='is-small marginLeft6'>Trial: {order.trialDate ? dateToDateString(order.trialDate) : null} </Button>
                    <Button className='is-small marginLeft6'>Delivery: {order.deliveryDate ? dateToDateString(order.deliveryDate) : null}</Button> */}
                    <span className='type55 has-text-semibold '> <span className='has-text-grey-light has-text-bold'>Book: </span>{order.bookingDate ? dateToDateString(order.bookingDate) : ""} </span>
                    <span className='type55 has-text-semibold marginLeft8'><span className='has-text-grey-light has-text-bold'>Trial: </span>{order.trialDate ? dateToDateString(order.trialDate) : ""} </span>
                    <span className='type55 has-text-semibold marginLeft8'><span className='has-text-grey-light has-text-bold'>Del: </span>{order.deliveryDate ? dateToDateString(order.deliveryDate) : ""}</span>
                </div>


                <div className='marginTop15'>
                    {/* <Button className='is-small is-light '> For &nbsp; {order.customerName ?? null}  &nbsp;  P: {order.customerPicsCount ?? null}  &nbsp;  M:{order.customerMeasurementsCount ?? null}</Button> */}
                    {clientDetailsVisibility() ? <Button className='is-small is-light ' onClick={() => { showClientInfoModalPressed() }}> For &nbsp; {order.customerName ?? null} </Button> : null}
                    {order.addedByUser ? (<span className='type50 has-text-weight-normal has-text-grey-light'>&nbsp; ·&nbsp; by {order.addedByUser.name} </span>) : null}
                    {order.isConciergeOrder ? <span> <span className="type50 has-text-weight-normal has-text-grey-light">·</span><Button className='is-small is-light marginLeft10 has-text-bold' onClick={showConciergeDetails} > <span className="conciergeTextColor has-text-bold">Order Details</span>  </Button> </span> : null}

                </div>

                {/* </Box> */}

                {/* <hr className="topMarginMinus16" /> */}

                <hr className="hrShadow" />


            </div>

        )
    }


    let actionBox = () => {
        if (selectedProducts.length > 0) {
            return (
                <Box className='has-background-grey stickyBottom has-text-centered '>
                    <div className="buttons has-addons is-centered">
                        {/* <Button >Print</Button> */}
                        {actingOnProductsAllowed() ? <Button className=' sendProductButton' loading={sending} disabled={sending} onClick={sendProductsPressed} >   {nextStageForScreen_Title(props.screen)}  &nbsp; <FontAwesomeIcon className='marginLeft10 is-primary' icon={faPaperPlane} />  </Button> : null}
                    </div>
                </Box>
            )
        }
    }

    searchProducts()




    const removeSentProductFromModal = (product) => {
        let prods = products.filter((prd) => {
            return prd.objectId !== product.objectId
        })
        if (prods) {
            if (prods.length === 0) {
                setNoProductsMessage('No Products Remaing')
                // orderHasMoved()
            }
        }
        setProducts(prods)
        closeModals()
    }


    const orderHasMoved = () => {
        if (props.orderHasMoved) {
            props.orderHasMoved(order)
        }
    }






    ///////



    const vendorIsChosen = (vendor) => {
        console.log('VENDOR IS SELECTED')
        console.log(vendor)

        closeModals()
        // continue Sending the product here now
        setTimeout(() => {
            sendProductNow(vendor)
        }, 1000)

    }


    const sendProductNow = (selectedVendor = null) => {

        if (selectedProducts.length === 0) {
            // alert('No Selected Products to send.')
            cogoToast.warn('No Products are selected to send')
            return
        }

        let dept = departmentForscreen(props.screen)
        // console.log("DEPT IS " + dept)
        let isVendor = (dept !== departments.Factory)
        if (props.screen !== screen.SendOrders_Store_Vendor) {
            isVendor = false
        }

        // Get Next Stage Value 
        let nextStage = nextStageForScreen(props.screen, isVendor)
        let nextStageTitle = nextStageForScreen_Title(props.screen, isVendor)

        // console.log('SENDING BULK PRODUCTS TO STAGE =' + nextStageTitle)
        // console.log('IS VENDOR =' + isVendor)
        // console.log('SELECTED VENDOR =' + selectedVendor)


        if (isVendor && selectedVendor == null) {
            console.log('SELECTED VENDOR LAST CHECK')
            console.log(selectedVendor)

            // alert('No Vendor Selected. Select Again or report a problem.')
            cogoToast.warn('No Vendor Selected. Select Vendor and send.')
            return
        }


        setSending(true)
        Product.sendMultipleProductsToNextStage(selectedProducts, isVendor, selectedVendor, null, nextStage, null, null, (succ, msg) => {
            if (succ) {

                let prods = [...products]
                let unselectedProducts = prods.filter((prd) => {
                    return (prd.isSelected !== true)
                })
                setSelectedProducts([])
                // alert('Products Sent to Next Stage')

                let completionTitle = nextStageCompletion_Title(props.screen, isVendor) ?? "";
                cogoToast.success(completionTitle)

                setProducts(unselectedProducts)
                if (unselectedProducts) {
                    if (unselectedProducts.length === 0) {
                        setNoProductsMessage('No Products Remaing')
                        // orderHasMoved()
                    }
                }

            } else {
                cogoToast.error('Could not send Products. Check internet and try again.')
                // alert(msg)
            }
            setSending(false)

        })

    }


    /////


    const sendProductsPressed = () => {
        // Get Selected Products
        if (selectedProducts.length === 0) {
            // alert('No Selected Products to send.')
            cogoToast.warn('No Selected Products to send')
            return
        }
        let dept = departmentForscreen(props.screen)
        let isVendor = (dept !== departments.Factory)
        if (props.screen !== screen.SendOrders_Store_Vendor) {
            isVendor = false
        }

        if (isVendor) {
            // show vendor Selection
            setShowVendors(true)

        } else {
            sendProductNow(null)

        }
    }



    const isMultiSendable = () => {
        if ([screen.ApproveTrial, screen.ApproveDelivery, screen.ApproveFinishing].includes(props.screen)) {
            return false
        }
        return true
    }


    const productTiles = () => {
        return (
            <div>


                {
                    (products.length > 0) ?

                        <Tile kind="ancestor" className=' productGrid' >
                            {/* <Fade right cascade> */}
                            {products.map((prd, index) => {
                                return (
                                    <Tile key={index} kind='parent' className=''>
                                        <Tile className='' >
                                            <ProductCard key={index} selectable={isMultiSendable()} screen={props.screen} product={prd} productSelected={productSelected} viewProductPressed={viewProductPressed} />
                                            {/* {pendingInvoiceBox()} */}
                                        </Tile>
                                    </Tile>
                                )
                            })}
                            {/* </Fade> */}
                        </Tile>

                        :

                        <p className=' marginTop60 is-centered has-text-centered has-text-grey-light has-text-weight-medium'> {noProductsMessage} </p>
                }


            </div>
        )
    }

    // const productTiles = 


    const thisCancelledProd = (prod) => {
        let cancelledByString = (prod.cancelledBy !== null) ? (`   ·  Cancelled  by ${prod.cancelledBy.name}`) : ""
        let desc = `${prod.productId} · ${prod.type}${cancelledByString}    ·   ${prod.cancellationInfo ?? ""}`

        return (
            <Card className=" has-background-light  pad10 marginTop10 is-shadowless">
                <p className=" type50 has-text-grey-light"> {desc} </p>
            </Card>
        )
    }

    const cancelledProds = (prods) => {

        if (prods.length === 0) {
            return null
        }

        return (
            <div className="is-paddingless">
                <br />
                <br />
                <br />
                <p className="type55 has-text-grey-light marginBottom10"> Cancelled Products </p>

                { prods.map((thisProd) => {
                    return thisCancelledProd(thisProd)
                })}
            </div>

        )

    }


    let thisSection = () => {

        if (showClientInfoModal) {
            return <ClientInfoModal customer={customer} closed={closeClientInfoModal} />
        }

        if (viewOrder != null) {
            return <OrderModal customer={props.customer} isNewOrder={false} order={viewOrder} products={products} orderAdded={null} orderUpdated={orderWasUpdated} closed={closeModals} />
        }

        if (viewProduct != null) {
            return <ProductModal isNewProduct={false} screen={props.screen} order={order} customer={props.customer ?? order.customer} product={viewProduct} closed={closeModals} productUpdated={productUpdated} productSent={removeSentProductFromModal} actingOnProductsAllowed={actingOnProductsAllowed()} />
        }

        if (addNewProduct === true) {
            return <ProductModal isNewProduct={true} totalProducts={products.length} customer={props.customer ?? order.customer} screen={props.screen} product={newProduct()} order={order} closed={closeModals} productAdded={productAdded} productUpdated={productUpdated} productSent={removeSentProductFromModal} />

        } else {

            return (
                <div className='marginTop20'>

                    { printingOrder ? <PrintOrder order={order} customer={customer} sheetType={PrintSheetType.orderSheet} products={products} closed={closeModals} /> : null}
                    { showVendors ? <VendorsModal isToChoose={true} vendorChosen={vendorIsChosen} closed={closeModals} /> : null}
                    { showConcOrderDetails ? <ConciergeOrderModal order={order} orderUpdated={orderWasUpdated} closed={closeModals} /> : null}


                    {orderInfoView(order)}
                    {/* <div className='is-size-7  has-text-centered  '>
                    <Button className='is-text is-small is-centered has-text-grey-light has-text-weight-medium no-decoration' > SELECT </Button>
                    <Button className='is-text is-small is-centered has-text-grey-light has-text-weight-medium  no-decoration' > PRINT </Button>
                    <br />
                </div> */}
                    {canAddProducts ? <Button className='is-small is-rounded is-pulled-right marginBottom10' onClick={addNewProductPressed}> Add </Button> : null}
                    <Heading className={canAddProducts ? " type70 has-text-grey-light  marginBottom6" : "type70 has-text-grey-light  marginBottom6 has-text-centered"} > {productsTitle} </Heading>
                    <ColumnAux size={9} isMiddle={true}>

                        {
                            productTiles()
                            // products.map((prd, index) => {
                            //     return <ProductCard key={index} product={prd} productSelected={productSelected} viewProductPressed={viewProductPressed} />
                            // })
                        }


                        {actionBox()}
                        {(cancelledProds.length > 0) ? cancelledProds(cancelledProducts) : null}

                    </ColumnAux>
                </div>
            )
        }

    }

    return (


        thisSection()

    )

}


export default ProductsOfOrder